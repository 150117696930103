import { useStoreState } from 'easy-peasy'
import React from 'react'
import { Dimensions, View, useWindowDimensions } from 'react-native'
import { Chat } from '../../components/Chat'

// const {width} = Dimensions.get('window')

const ChatPatientScreen = () => {
    const {width} = useWindowDimensions();
    const userpk = useStoreState(state => state.userpk);
    const therapistPk = useStoreState(state => state.therapistPk)
    return (
            <View  style={{flex:1, margin:"auto", width: width>768?width*0.8:"100%"}}>
                <Chat userpk ={userpk} recipient = {therapistPk} />
            </View>
    )
}

export default ChatPatientScreen


/// Vamos a cmabiar Example por chat y eliminar el old. Luego hacer un marco arrribva con ela vatar y el nombre en grande y pasar por props el link y nombre para abajo.