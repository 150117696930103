import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";
import DashPatientScreen from '../../patient/DashPatientScreen';
import NotificationChatPatient from '../../../components/NotificationChatPatient';
import AvatarNavHeader from '../../../components/AvatarNavHeader';

const DashPatientStack = createStackNavigator();
const DashPatientStackScreen = ({ navigation }) =>{
  
  return(
    <DashPatientStack.Navigator>
      <DashPatientStack.Screen
        name={"DashPatientScreen"}
        component={DashPatientScreen}
        options={{
          title: "Inicio",
          headerLeft : () => {
            return <AvatarNavHeader />
          },
          headerRight : () => {
            return (
              <NotificationChatPatient />
            )
          },
        }}
      />
      {/* <DashPatientStack.Screen
        name={"Detalles de Dash Screen"}
        component={Detalles de Dash Screen}
        options={{
          title: "Editar perfil",
        }}
    /> */}
    </DashPatientStack.Navigator>
  )
}
export default DashPatientStackScreen