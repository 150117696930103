import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";
import NewEventRecordPatientScreen from '../../patient/NewEventRecordPatientScreen';
import AvatarNavHeader from '../../../components/AvatarNavHeader';

const NewEventRecordPatientStack = createStackNavigator();
const NewEventRecordPatientStackScreen = ({ navigation }) =>{
  
  return(
    <NewEventRecordPatientStack.Navigator>
      <NewEventRecordPatientStack.Screen
        name={"NewEventRecordPatientScreen"}
        component={NewEventRecordPatientScreen}
        options={{
          title: "EventRecord",
          headerLeft : () => {
            return <AvatarNavHeader />
          },
        }}
      />
      {/* <ChatPatientStack.Screen
        name={"Detalles de Chat Screen"}
        component={Detalles de Chat Screen}
        options={{
          title: "Editar perfil",
        }}
    /> */}
    </NewEventRecordPatientStack.Navigator>
  )
}
export default NewEventRecordPatientStackScreen