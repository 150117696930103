import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";
import ChatsTherapistScreen from '../../therapist/ChatsTherapistScreen';
import ChatTherapistScreen from '../../therapist/ChatTherapistScreen';
import AvatarNavHeader from '../../../components/AvatarNavHeader';
const ChatTherapistStack = createStackNavigator();
const ChatTherapistStackScreen = ({ navigation }) =>{
  
  return(
    <ChatTherapistStack.Navigator>
      <ChatTherapistStack.Screen
        name={"ChatsTherapistScreen"}
        component={ChatsTherapistScreen}
        options={{
          title: "Chats de pacientes",
          headerLeft : () => {
            return <AvatarNavHeader />
          },
        }}
      />
      <ChatTherapistStack.Screen
        name={"ChatTherapistScreen"}
        component={ChatTherapistScreen}
        options={{
          title: "Chat",
        }}
      />
      {/* <ChatTherapistStack.Screen
        name={"Detalles de Chat Screen"}
        component={Detalles de Chat Screen}
        options={{
          title: "Editar perfil",
        }}
    /> */}
    </ChatTherapistStack.Navigator>
  )
}
export default ChatTherapistStackScreen