import React, { useState } from 'react'
import { View, Text, TextInput, Image, TouchableOpacity, Linking } from 'react-native'
import { CheckBox, Icon } from 'react-native-elements'
import Ionicons from '@expo/vector-icons/Ionicons';
import check from '../../assets/icons/checkboxcheck.png'
import uncheck from '../../assets/icons/checkboxuncheck.png'
import axios from 'axios';
import { API_URL } from '../core/api';
import { useStoreState } from 'easy-peasy';
import { Avatar } from 'react-native-elements/';
import Therapist from '../../assets/icons/Therapist.png'

const SignUpScreen = ({ navigation }) => {

    const [errorText, setErrorText] = useState(null)
    const [username, setUsername] = useState(null)
    const [name, setName] = useState(null)
    const [mail, setMail] = useState(null)
    const [pass, setPass] = useState(null)
    const [rePass, setRePass] = useState(null)
    const [acceptTerms, setAcceptTerms] = useState(false)
    const [declareTherapist, setDeclareTherapist] = useState(false)
    const [applicationSent, setApplicationSent] = useState(false)

    const Config = useStoreState(state => state.AuthConfig)

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };

    function handleSubmit () {
       if (username === undefined || username == null || username.length == 0 ||
        name === undefined || name == null || name.length == 0 ||
        mail === undefined || mail == null || mail.length == 0 ||
        pass === undefined || pass == null || pass.length == 0) {
            setErrorText('Todos los campos son necesarios')
        }else{
       if (acceptTerms){
        if (acceptTerms){
           if (pass === rePass){
               if (validateEmail(mail)){
                   let data = {
                       username: username,
                       name: name,
                       email: mail,
                       password: pass,
                   }
                   axios.post(API_URL+'user/registerTherapist/', data,Config)
                   .then(setApplicationSent(true))
                   .catch(error => console.log(error))
                console.log('envimaos')
               }else{
                   setErrorText('Introduce un email válido')
               }
           }else{
               setErrorText('Las contraseñas deben coincidir')
           }
        }else{
            setErrorText('Debes declarar que eres terapeuta en ejercicio')
        }
       }else{
           setErrorText('Debes aceptar los Términos y Condiciones')
       }
       }
    }

    if (applicationSent){
    return(
        <View style={{flex:1, margin: 30, alignItems:'center', justifyContent:'center'}}>
           <Text style={{fontSize:20}}>¡Registrado como Terapeuta!{"\n"}{"\n"}</Text>
           <Text style={{fontSize:16}}>Hemos enviado un mensaje a tu correo electornico ({mail}) para crear tu cuenta de terapeuta. Por favor, haz click al enlace de activación que te hemos enviado en él.{"\n"}{"\n"}</Text>
           <TouchableOpacity onPress={() => {
                   navigation.navigate("SignIn")
                }} style={{marginHorizontal:20}}>
                  <Text style={{color:'#3e5ba6'}}>Cuando actives tu cuenta, accede al Login {'->'}</Text>
</TouchableOpacity>
        </View>
    )
    }else{
    return (
        <View style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginHorizontal:'auto'
        }}>
            <Avatar source={Therapist} rounded={true} size={100} />
            <Text style={{color: "purple"}}>{errorText}</Text>
            <Text>Nombre de usuario:</Text>
            <TextInput
            onChangeText={txt => setUsername(txt)}
            placeholder="Por ejemplo: fermartinez"
            style={{ marginTop: 20, width:250 }} //give custom styles
            />
            <Text>{"\n"}Nombre completo:</Text>
            <TextInput
            onChangeText={txt => setName(txt)}
            placeholder="Por ejemplo: Fernando Martínez"
            style={{ marginTop: 20, width:250 }} //give custom styles
            />
            <Text>{"\n"}Correo electrónico:</Text>
            <TextInput
            onChangeText={txt => setMail(txt)}
            placeholder="Por ejemplo: fernando@martinez.es"
            style={{ marginTop: 20, width:250 }} //give custom styles
            />
            <Text>{"\n"}Contraseña:</Text>
            <TextInput
            secure={true}
            secureTextEntry={true}
            onChangeText={txt => setPass(txt)}
            placeholder="Contraseña"
            style={{ marginTop: 20, width:250 }} //give custom styles
            />
            <TextInput
            secure={true}
            secureTextEntry={true}
            onChangeText={txt => setRePass(txt)}
            placeholder="Repite la contraseña"
            style={{ marginTop: 20, width:250 }} //give custom styles
            />
            
            <Text>{"\n"}</Text>
<CheckBox
  center
  title='Declaro que soy terapeuta en ejercicio'
  checkedIcon={<Image source={check} style={{width:30, height:30}} />}//'dot-circle-o'
  uncheckedIcon={<Image source={uncheck} style={{width:30, height:30}} />}//'circle-o'
  checked={declareTherapist}
  onPress={() => setDeclareTherapist(!declareTherapist)}
/>        
<CheckBox
  center
  title='Acepto los Términos y Condiciones'
  checkedIcon={<Image source={check} style={{width:30, height:30}} />}//'dot-circle-o'
  uncheckedIcon={<Image source={uncheck} style={{width:30, height:30}} />}//'circle-o'
  checked={acceptTerms}
  onPress={() => setAcceptTerms(!acceptTerms)}
/>
<TouchableOpacity onPress={ ()=>{ Linking.openURL('https://psykehub.com/politicadeprivacidad')}} style={{marginHorizontal:20}}>
                  <Text style={{color:'#666666'}}>Consulta aquí las Políticas de privacidad y Condiciones de uso</Text>
</TouchableOpacity>

            {/* <Text>{"\n"}</Text> */}

            <TouchableOpacity
                style={{
                    backgroundColor: '#3e5ba6',
                    paddingHorizontal: 40, 
                    borderRadius: 20,
                    paddingVertical: 8,
                    margin: 6,
                    alignContent: 'center'
                }}
                onPress={handleSubmit}
            >
                <Text style={{
                    color: '#fff',
                    fontSize: 20
                }}>Registrar Terapeuta</Text>
            </TouchableOpacity>
            <Text>{"\n"}</Text>
            <TouchableOpacity onPress={() => {
                    navigation.navigate("SignIn")
                }} >
                  <Text style={{color:'#3e5ba6'}}>¿Ya estás registrado? Accede {'->'}</Text>
            </TouchableOpacity>
            <Text>{"\n"}</Text>
            <TouchableOpacity onPress={() => {
                    navigation.navigate("SignUpUser")
                }} >
                  <Text style={{color:'#3e5ba6'}}>¿No eres terapeuta? Haz una cuenta de usuario {'->'}</Text>
            </TouchableOpacity>
        </View>
    )}
}

export default SignUpScreen
