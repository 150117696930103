import { useStoreState } from 'easy-peasy';
import React, {useState} from 'react'
import { View, Text, StyleSheet, TouchableHighlight, TouchableOpacity, TextInput, useWindowDimensions, Image, ScrollView } from 'react-native'
import moment from 'moment';
import { LinearGradient } from 'expo-linear-gradient';
import EventRecordPic from '../../assets/EventRecord.png'
import { Rating} from 'react-native-elements';

import None from '../../assets/SAM/None.png'
import P1 from '../../assets/SAM/P1.png'
import P2 from '../../assets/SAM/P2.png'
import P3 from '../../assets/SAM/P3.png'
import P4 from '../../assets/SAM/P4.png'
import P5 from '../../assets/SAM/P5.png'
import D1 from '../../assets/SAM/D1.png'
import D2 from '../../assets/SAM/D2.png'
import D3 from '../../assets/SAM/D3.png'
import D4 from '../../assets/SAM/D4.png'
import D5 from '../../assets/SAM/D5.png'
import A1 from '../../assets/SAM/A1.png'
import A2 from '../../assets/SAM/A2.png'
import A3 from '../../assets/SAM/A3.png'
import A4 from '../../assets/SAM/A4.png'
import A5 from '../../assets/SAM/A5.png'
import axios from 'axios';
import { API_URL } from '../core/api';
import { useNavigation } from '@react-navigation/native';


const NewEventRecord = () => {
   
    const {width} = useWindowDimensions();
    const Config = useStoreState(state => state.AuthConfig)
    //const isTherapist = useStoreState(state => state.is_therapist)
    const typeuser = useStoreState(state => state.typeuser);
    //console.log('typeuseeer', typeuser)
    const navigation = useNavigation()

    moment.locale('es')
    // let fecha_origin = moment().local().format('DD [de] MMM [a las] hh:mm A');
    // console.log('Fecha en local: ', fecha_origin);


    const [eventDescription, setEventDescription] = useState();
    const [eventContext, setEventContext] = useState();
    const [eventSolution, setEventSolution] = useState();
    const [eventFeeling, setEventFeeling] = useState();
    const [pl, setPl] = useState();
    const [ar, setAr] = useState();
    const [dm, setDm] = useState();
    const [anychange, setAnychange] = useState(false);
    const [savedchange, setSavedchange] = useState(false);
    

    const imageP = {
      null: None,
      0: None,
      1: P1,
      2: P2,
      3: P3,
      4: P4,
      5: P5
    }
    const imageA = {
      null: None,
      0: None,
      1: A1,
      2: A2,
      3: A3,
      4: A4,
      5: A5
    }
    const imageD = {
      null: None,
      0: None,
      1: D1,
      2: D2,
      3: D3,
      4: D4,
      5: D5
    }

    function sendEventRecord () {
      const Event = {event_description: eventDescription, event_context: eventContext, event_solution: eventSolution, event_feeling: eventFeeling, pleasure: pl, arousal: ar, dominance: dm}
      axios.post(API_URL+'eventrecords/create/', Event, Config)
      .then(setAnychange(false), setSavedchange(true), console.log("Event recorded!"))
    }


    return(
      <ScrollView style={{backgroundColor:'white'}}>
      <View style={{
          flex: 1, backgroundColor:'white', alignItems: 'center', width: width>768?width*0.8:"100%", margin:"auto", paddingHorizontal:5
      }}>
          
          <LinearGradient
      // Linear Gradient  - In PRO put picture
      //colors={['#3e5ba6', '#444cb3', '#843361'] }
              /////colors={['#2363a3', '#343556', '#6b3b4e']}//
              colors={['#2363a3', '#3e5ba6', '#6e3c64']}//
      style={{ width:"100%"}}
      >
        <View style={{width:'100%', alignItems:'center'}}>
          {/* <Image source={divan} width={200} height={300} /> */}
          <Image source={EventRecordPic} style={{width:350, height:180}} />
          <Text>{"\n"}</Text>
        </View>
        </LinearGradient>

        {!savedchange? (
        <View style={{width:"100%", flex:1, backgroundColor:'white',
        paddingTop: 50,
        backgroundColor: '#FFFFFF',
        borderTopRightRadius: 30,
        borderTopLeftRadius: 30,
        top: -30,
        alignItems: "center"}}>

        <View>
          <Text style={[styles.customTitle, {color:'#843361'}]}> Registra un suceso, un evento importante, una situación dificil o un momento feliz. ¡Pero guárdalo! {"\n"}{"\n"}</Text>
          <Text><Text  style={styles.customTitle}>Describe lo sucedido:{"\n"}</Text> </Text>
          <TextInput style={{backgroundColor:'#FFF', width:"90%", margin:'auto', borderColor:'#777777', borderWidth:2, borderRadius:5}}
            name = "comment"
            underlineColorAndroid='transparent'
            multiline={true}
            numberOfLines={5}
            onChangeText={(value) => {setEventDescription(value), setAnychange(true)}}/>
          <Text><Text  style={styles.customTitle}>¿En qué contexto se ha producido? ¿Dónde? ¿Cuándo? ¿Acompañado de alguien?:{"\n"}</Text> </Text>
          <TextInput style={{backgroundColor:'#FFF', width:"90%", margin:'auto', borderColor:'#777777', borderWidth:2, borderRadius:5}}
            name = "comment"
            underlineColorAndroid='transparent'
            multiline={true}
            numberOfLines={5}
            onChangeText={(value) => {setEventContext(value), setAnychange(true)}}/>
          <Text><Text  style={styles.customTitle}>¿Cómo has solucionado la situación, qué estrategia tomaste?:{"\n"}</Text> </Text>
          <TextInput style={{backgroundColor:'#FFF', width:"90%", margin:'auto', borderColor:'#777777', borderWidth:2, borderRadius:5}}
            name = "comment"
            underlineColorAndroid='transparent'
            multiline={true}
            numberOfLines={5}
            onChangeText={(value) => {setEventSolution(value), setAnychange(true)}}/>
          
          <Text>{"\n"}</Text>

          <Text style={[styles.customTitle, {color:'#843361'}]}> Sensaciones y emociones del evento {"\n"}</Text>
          <Text><Text  style={[styles.customTitle, {paddingTop:20}]}>Evalúa ćomo te has sentido: (1-5){"\n"}</Text> </Text>
          <Text style={{fontWeight:'bold', color:'#F43E3E'}}>Valencia afectiva:</Text>

          <View style={{flexDirection:'row'}}>
            <Rating
              type='custom'
              //ratingImage={WATER_IMAGE}
              ratingImage={null}
              ratingColor='#3e5ba6'//'#3498db'
              ratingBackgroundColor='#c8c7c8'
              ratingCount={5}
              minValue = {1}
              startingValue = {pl}
              imageSize={30}
              showRating
              style={{padding:2}}
              onFinishRating = {(value) => {setPl(value), setAnychange(true)}}
              // in case of therapist: readonly
            />
            <Image source={imageP[pl]} style={{width:100, height:100}} />
          </View>

          <Text style={{fontWeight:'bold', color:'#96BC5C'}}>Activación o arousal:</Text>

          <View style={{flexDirection:'row'}}>
            <Rating
              type='custom'
              //ratingImage={WATER_IMAGE}
              ratingImage={null}
              ratingColor='#3e5ba6'//'#3498db'
              ratingBackgroundColor='#c8c7c8'
              ratingCount={5}
              minValue = {1}
              startingValue = {ar}
              imageSize={30}
              showRating
              style={{padding:2}}
              onFinishRating = {(value) => {setAr(value), setAnychange(true)}}
              // in case of therapist: readonly
            />
            <Image source={imageA[ar]} style={{width:100, height:100}} />
          </View>

          <Text style={{fontWeight:'bold', color:'#AB7854'}}>Dominancia:</Text>

          <View style={{flexDirection:'row'}}>
            <Rating
              type='custom'
              //ratingImage={WATER_IMAGE}
              ratingImage={null}
              ratingColor='#3e5ba6'//'#3498db'
              ratingBackgroundColor='#c8c7c8'
              ratingCount={5}
              minValue = {1}
              startingValue = {dm}
              imageSize={30}
              showRating
              style={{padding:2}}
              onFinishRating = {(value) => {setDm(value), setAnychange(true)}}
              // in case of therapist: readonly
            />
            <Image source={imageD[dm]} style={{width:100, height:100}} />
          </View>
          
          <Text style={{paddingTop:15}}><Text  style={styles.customTitle}> Describe cómo te has sentido:  {"\n"}</Text> </Text>
          <TextInput style={{backgroundColor:'#FFF', width:"90%", margin:'auto', borderColor:'#777777', borderWidth:2, borderRadius:5}}
            name = "comment"
            underlineColorAndroid='transparent'
            multiline={true}
            numberOfLines={5}
            onChangeText={(value) => {setEventFeeling(value), setAnychange(true)}}/>

          {anychange? (<TouchableHighlight style={{backgroundColor: '#3e5ba6', margin:'auto', height:30, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin:20, width:120, borderRadius:30,}}
          //onPress={updateAppointmentPatient}>
          onPress={sendEventRecord}>
            <Text style={{color: 'white',}}>Registrar evento</Text>
          </TouchableHighlight>) : null }
        </View>
        



        </View>
        ):(<View>
        
        <Text style={{ margin:20, color: '#444cb3', fontSize:18, fontWeight:'bold'}}>!Tu evento ha sido guardado!</Text>
        <Text style={{ margin:20, }}>Puedes ver tu evento en 
          <TouchableOpacity onPress={() => {
                    navigation.navigate("CalendarPatientStackScreen")
                }} >
                  <Text style={{color:'#3e5ba6', fontWeight:'bold'}}> Calendario {'->'}</Text>
            </TouchableOpacity>
           o volver al menú de 
           <TouchableOpacity onPress={() => {
                    navigation.navigate("DashPatientStackScreen")
                }} >
                  <Text style={{color:'#3e5ba6', fontWeight:'bold'}}> Inicio {'->'}</Text>
            </TouchableOpacity>
            </Text>
        </View>)}

      </View>
      </ScrollView>
    )
}

export default NewEventRecord



const styles = StyleSheet.create({
    customTitleContainer: {
      flexDirection: 'row', 
      alignItems: 'center', 
      padding: 10
    },
    customTitle: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#3e5ba6'
      // color: '#00BBF2'
    }
  });