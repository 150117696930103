import { TouchableOpacity, View } from "react-native"
import { Avatar, Image } from 'react-native-elements';
import { MEDIA_URL } from "../core/api";
import { useStoreState } from "easy-peasy";
import menuButtonRoundedShort from '../../assets/menuButtonRoundedShort.png'
import { useNavigation } from "@react-navigation/native";

const AvatarNavHeader = (props) => {
    const avatar = useStoreState((state) => state.avatar);
    const navigation = useNavigation()
return(
    <TouchableOpacity 
    style={{
      paddingHorizontal: 10,
      // flexDirection:'row'
    }}
    onPress={() => navigation.toggleDrawer()}
  >
    <View style={{flexDirection:'row', justifyContent:'center'}}>
    <Image source={menuButtonRoundedShort} style={{paddingTop:10, width: 30, height: 34, marginVertical:'auto'}} />
    <Avatar rounded={true} source={{uri: MEDIA_URL+avatar}} />
    </View>
  </TouchableOpacity>
)
}

export default AvatarNavHeader