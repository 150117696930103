import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";
import ProfileScreen from '../profile/ProfileScreen';
import EditProfileScreen from '../profile/EditProfileScreen';
import { Image, TouchableOpacity, StyleSheet } from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useStoreState } from 'easy-peasy';
import { MEDIA_URL } from '../../core/api';
import { Avatar } from 'react-native-elements';
import NotificationChat from '../../components/NotificationChatTherapist';

const ProfileStack = createStackNavigator();
const ProfileStackScreen = ({ navigation }) =>{
  const avatar = useStoreState((state) => state.avatar);
  return(
    <ProfileStack.Navigator>
      <ProfileStack.Screen
        name={"ProfileScreen"}
        component={ProfileScreen}
        options={{
          title: "Perfil",
          headerLeft : () => {
            return (
              <TouchableOpacity 
                style={{
                  paddingHorizontal: 10
                }}
                onPress={() => navigation.toggleDrawer()}
              >
                {/* <Ionicons
                    name="ios-menu"
                    size={40}
                    color={"#000"}
                /> */}
                {/* <Image style={styles.avatar} source={{uri: MEDIA_URL+avatar}}></Image> */}
                <Avatar rounded={true} source={{uri: MEDIA_URL+avatar}} />
              </TouchableOpacity>
            )
          },
          headerRight : () => {
            return (
              <NotificationChat />
            )
          },
        }}
      />
      <ProfileStack.Screen
        name={"EditProfileScreen"}
        component={EditProfileScreen}
        options={{
          title: "Editar perfil",
        }}
    />
    </ProfileStack.Navigator>
  )
}
export default ProfileStackScreen
