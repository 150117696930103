import React from "react";
import { Linking, View, Text, useWindowDimensions } from "react-native";
import { Image } from "react-native-elements";
import Logo from '../../../assets/LogoPSYKEHUBlowr.png'

export default function Footer () {
    const {width, height} = useWindowDimensions()
    return(<>
    {width>500?
        <View style={{backgroundColor:'#333333', marginTop:50, padding:10, justifyContent:'center', alignItems:'center', flexDirection:'row'}}>
            <Text onPress={() => window.location = '/'} style={{ color:'white'}}> Inicio </Text>
            <Text onPress={() => window.location = '/avisolegal'} style={{ color:'white'}}> Aviso legal </Text>
            <Text onPress={() => window.location = '/politicadeprivacidad'} style={{ color:'white'}}> Política de Privacidad </Text>
            <Text onPress={() => window.location = '/politicadecookies'} style={{ color:'white'}}> Política de Cookies   </Text>
            <Image source={Logo} style={{width:125, height:35, tintColor:'black'}} />
            <Text style={{ color:'white'}}>   contacto@psykehub.com </Text>
        </View>
        :
        <View style={{ marginTop:50, backgroundColor:'#333333', flexDirection:'row'}}>
            <View style={{width: width*0.3, padding:10, paddingRight:10, justifyContent:'center', alignItems:'center'}}>
                <Image source={Logo} style={{width:125, height:75, tintColor:'black'}} />
            </View>
            <View style={{width:width*0.6, padding:10, justifyContent:'center', flexDirection:'column', borderLeftColor:'#666666', borderLeftWidth:2}}>
                <Text onPress={() => window.location = '/'} style={{ color:'white'}}> Inicio </Text>
                <Text onPress={() => window.location = '/avisolegal'} style={{ color:'white'}}> Aviso legal </Text>
                <Text onPress={() => window.location = '/politicadeprivacidad'} style={{ color:'white'}}> Política de Privacidad </Text>
                <Text onPress={() => window.location = '/politicadecookies'} style={{ color:'white'}}> Política de Cookies   </Text>
                <Text style={{ color:'#CCCCCC'}}> contacto@psykehub.com </Text>
            </View>
        </View>}
    </>)
}

