import React, { Component } from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Button,
  Dimensions,
  useWindowDimensions
} from 'react-native';
import { MEDIA_URL } from '../../core/api';
import { useStoreState } from 'easy-peasy';
import { LinearGradient } from 'expo-linear-gradient';

const ProfileScreen = ({navigation}) => {
  const {width} = useWindowDimensions();
    const avatar = useStoreState((state) => state.avatar);
    const name = useStoreState((state) => state.name);
    const username = useStoreState((state) => state.username);
    const bio = useStoreState((state) => state.bio);
    const status = useStoreState((state) => state.status);
    return (
        <View style={{width: width > 768?"80%":"100%", marginHorizontal:"auto", backgroundColor:'white'}}>{/* {styles.container}> */}
        <LinearGradient
        ///colors={['#6ba4e1', '#4f6fbd', '#b362d5'] }
                /////colors={['#2363a3', '#343556', '#6b3b4e']}//
                colors={['#2363a3', '#3e5ba6', '#6e3c64']}//
        style={{width:"100%"}}>
          <View style={{height:200}}></View>
        </LinearGradient>
          
                    <View style={{backgroundColor:'white', alignItems:'center', height:"100%", borderTopLeftRadius: 30, top: -30}}>
           


            <View style={{marginTop:100, paddingHorizontal:10, width:"100%"}}> 
              <Text style={styles.info}>Biografía</Text>
              <Text style={styles.description}>{bio}</Text>
              <Text style={styles.info}>Ocupación</Text>
              <Text style={styles.description}>{status}</Text>
                       
            </View>
        </View>

          <Image style={styles.avatar} source={{uri: MEDIA_URL+avatar}}/>

            <Text style={{
              fontSize:22,
              fontWeight:'600',
              left:150,
              top:200,
              position:"absolute"
            }}>{name}
          
          <Text style={styles.info}>{"\n"}{username}</Text></Text>

           <TouchableOpacity style={styles.buttonContainer} 
                onPress={() => { navigation.navigate('EditProfileScreen'); }}>
                <Text style={{color:"white"}}>Editar Perfil</Text>  
            </TouchableOpacity>    
      </View>
    )
}

export default ProfileScreen

// const width = Dimensions.get('window').width

const styles = StyleSheet.create({
  // header:{
  //   //backgroundColor: "#00BFFF",
  //   //backgroundColor : rgb(168, 48, 220),
  //   height:200,
  // },
  // container:{
  //   // width: width > 768?"80%":"100%",
  //   // marginHorizontal:"auto"
  // },
  avatar: {
    width: 130,
    height: 130,
    borderRadius: 63,
    borderWidth: 4,
    borderColor: "white",
    marginBottom:10,
    alignSelf:'flex-start',
    position: 'absolute',
    // marginTop:130
    top:130,
    left:15
  },
  name:{
    fontSize:22,
    // color:"#222222",
    fontWeight:'600',
    left:150,
    top:130,
    position:"absolute"
  },
  body:{
    marginTop:0,
    backgroundColor:'white',
  },
  bodyContent: {
    flex: 1,
    alignItems: 'center',
    padding:30,
  },
  name:{
    fontSize:28,
    color: "#696969",
    fontWeight: "600"
  },
  info:{
    fontSize:16,
    color: "#00BFFF",
    marginTop:10
  },
  description:{
    fontSize:16,
    color: "#696969",
    marginTop:10,
    textAlign: 'center'
  },
  buttonContainer: {
    marginTop:10,
    height:45,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    // marginBottom:20,
    width:100,
    borderRadius:10,
    backgroundColor: '#3e5ba6',
    position:'absolute',
    right:7,
    //elevation:10,
    borderColor: 'white',
    borderWidth: 0.2
  },
});

                               