import React, { useState, useEffect } from 'react'
import { View, Text, TextInput, StyleSheet } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useNavigation } from '@react-navigation/native';
import { useStoreState, useStoreActions } from 'easy-peasy';
import axios from 'axios';
import { API_URL } from '../core/api';
//import AsyncStorage from '@react-native-async-storage/async-storage';

const LoginScreen = (props) => {
    const isloggedin = useStoreState(state => state.isLoggedin);
    const loggin = useStoreActions(action => action.loggin)
    const LogginAccount = useStoreActions(action => action.LogginAccount)
    const LogginStorage = useStoreActions(action => action.LogginStorage)

    var valueUser; var valuePass; var errorLogin; //var authdata
    const [errorText, setErrorText] = useState('');
   
    const handleOnChangeUsername = (value) => {
        // this.setState({
        //   username: value,
        // })
        valueUser = value;
        //console.log(valueUser, 'TEST U')
        }
    const handleOnChangePassword = (value) => {
        valuePass = value;
        }
    var result;
    const handleOnSubmit = async () => {
        console.log(API_URL+'user/login/', valueUser, valuePass)
        axios.post(API_URL+'user/login/', {username: valueUser, password: valuePass}, null)
        .then(res => {
          //authdata = res.data
          
          LogginAccount(res.data)
          console.log('AT LOGGING', res.data)
          // console.log(res.data)
          // result = res.data
          // console.log('Check 222', result, res.data)
          
          // Claro aquí hay que decidir a dónde vamos a ir.. 
          // Quizás lo mejor es meter en STATE STORe si es therapist o user. 


        })
        .catch( error => {
          errorLogin = error.message
          console.log(errorLogin)
          setErrorText('No es posible verificar las credenciales, inténtalo de nuevo')
        })
         //await AsyncStorage.setItem("token", result.token);
         //console.log(result, 'resultt')
         //console.log(await AsyncStorage.getItem('token'))
        }
      

    return (
        <View style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            // width: 200,
            marginHorizontal:'auto'
        }}>
            <Text style={{color: "purple"}}>{errorText}</Text>
            <TextInput
            //onChangeText={(value) => console.log(value)}
            onChangeText={handleOnChangeUsername}
            placeholder="Nombre de Usuario"
            style={{ marginTop: 20, width:250 }} //give custom styles
            />

            <TextInput
            //onChangeText={(value) => console.log(value)}
            onChangeText={handleOnChangePassword}
            placeholder="Contraseña"
            secure={true} //we pass secure component to identify its password
            style={{ marginTop: 20, width:250 }} //give custom styles
            secureTextEntry={true}
            />
            <Text>{"\n"}</Text>

            <TouchableOpacity
                style={{
                    backgroundColor: '#3e5ba6',
                    paddingHorizontal: 40, 
                    borderRadius: 20,
                    paddingVertical: 8,
                    margin: 6,
                    // width: 180,
                    alignContent: 'center'
                }}
                // onPress={() => {
                //     loggin()
                // }}
                onPress={handleOnSubmit}
            >
                <Text style={{
                    color: '#fff',
                    fontSize: 20
                }}>Acceder</Text>
            </TouchableOpacity>
            <Text>{"\n"}</Text>
            <TouchableOpacity onPress={() => {
                    props.navigation.navigate("SignUp")
                }} >
                  <Text style={{color:'#3e5ba6'}}>¿Aún no te has registrado?</Text>
            </TouchableOpacity>
        </View>
    )
}

const styles = StyleSheet.create({
 
    formulario: {
      color: 'black',
      fontSize: 18,
      marginTop: 20,
      fontWeight: '600',
      padding: 4,
      paddingRight: 12,
      textAlign: 'center',
    },
   
    nombresyapellidos: {
      color: 'black',
      fontSize: 18,
      marginTop: 20,
      marginLeft: 20,
      marginRight: 20, 
      fontWeight: '600',
      paddingLeft: 20,
      borderWidth: 1,
      borderRadius: 7,
      borderColor: 'black',
      paddingRight: 12,
    }, 
   
    email: {
      color: 'black',
      fontSize: 18,
      marginTop: 20,
      marginLeft: 20,
      marginRight: 20, 
      fontWeight: '600',
      paddingLeft: 20,
      borderWidth: 1,
      borderRadius: 7,
      borderColor: 'black',
      paddingRight: 12,
    }, 
   
    telefono: {
      color: 'black',
      fontSize: 18,
      marginTop: 20,
      marginLeft: 20,
      marginRight: 20, 
      fontWeight: '600',
      paddingLeft: 20,
      borderWidth: 1,
      borderRadius: 7,
      borderColor: 'black',
      paddingRight: 12,
    },
   
    mensaje: {
      color: 'black',
      fontSize: 18,
      marginTop: 20,
      marginBottom: 20,
      marginLeft: 20,
      marginRight: 20, 
      fontWeight: '600',
      paddingLeft: 20,
      borderWidth: 1,
      borderRadius: 7,
      borderColor: 'black',
      paddingRight: 12,
    },
   
    colorBtn: {
      borderWidth: 1,
      borderColor: '#007BFF',
      backgroundColor: '#007BFF',
      padding: 15,
      marginLeft: 20,
      marginRight: 20,
      borderRadius: 7,
    },
   
    colorTxtBtn: {
      color: '#FFFFFF',
      fontSize: 20,
      textAlign: 'center'
    }
   
  });


export default LoginScreen

