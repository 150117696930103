import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";
import DashTherapistScreen from '../../therapist/DashTherapistScreen';
import NotificationChatTherapist from '../../../components/NotificationChatTherapist';
import AvatarNavHeader from '../../../components/AvatarNavHeader';

const DashTherapistStack = createStackNavigator();
const DashTherapistStackScreen = ({ navigation }) =>{


  return(
    <DashTherapistStack.Navigator>
      <DashTherapistStack.Screen
        name={"DashTherapistScreen"}
        component={DashTherapistScreen}
        options={{
          title: "Inicio",
          headerLeft : () => {
            return <AvatarNavHeader />
          },
          headerRight : () => {
            return (
              <NotificationChatTherapist />
            )
          },
        }}
      />
      {/* <DashTherapistStack.Screen
        name={"Detalles de Dash Screen"}
        component={Detalles de Dash Screen}
        options={{
          title: "Editar perfil",
        }}
    /> */}
    </DashTherapistStack.Navigator>
  )
}
export default DashTherapistStackScreen