import React, { useEffect, useState } from "react";
import { View, Text, Linking, Image, useWindowDimensions, TextInput } from "react-native";
import {BlurView} from "expo-blur";
import logo from '../../../assets/LogoPSYKEHUBlowr.png'
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../core/api";
import { render } from "react-dom";


function Passwordreset () {
    const {width, height} = useWindowDimensions()
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [errorText, setErrorText] = useState('');
    const [changed, setChanged] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const {user, token} = useParams();

    function getAuth () {
        axios.get(API_URL+'user/passwordreset/'+user+'/'+token)
        .then(res => {
        setAuthorized(true)
        })
    }
    
    function handleSubmit(){
        if(password1 == ''){
          setErrorText('No se ha introducido ninguna contraseña')
        }else if (password1 != password2){
          return setErrorText('Las contraseñas no coinciden')
        }else if (password1.length < 6){
          return setErrorText('La contraseña debe contener al menos 6 caracteres')
        }else{
          const formData = new FormData();
          formData.append('password', this.state.password1);
          formData.append('token', this.props.match.params['token']);
          formData.append('uidb64', this.props.match.params['user']);
          axios.patch(API_URL+'user/passwordreset-complete/', formData)
          .then(res => { setChanged(res.data.success)})
          .catch( error => {
                setErrorText('La autorización del enlace es inválida')
               })
        }  
      }
    
    useEffect(() => getAuth(), [])

    if(authorized){
        if(changed){
            return(
            <>
            <Image source={logo} style={{width:750, height:450, position:'absolute', left:(width/2)-300, top:(height/2)-225}} />
            <BlurView intensity={90} tint={"light"}
                style={{padding:100, borderRadius:20, margin:'auto', alignItems:'center', justifyContent:'center', width:"100%"}} >
                <Text style={{fontSize:20}}><Text style={{fontWeight:'bold'}}>Se ha realizado el cambio de contraseña correctamente.{"\n"}</Text> Ya puede ingresar en la plataforma con su nueva contraseña.{"\n"}{"\n"}</Text>
                <Text onPress={() => Linking.openURL('/')} style={{color:'#3e5ba6'}}>Volver al inicio {'->'}</Text>
            </BlurView>
            </>
            )
        }else{
            return(
            <>
            <Image source={logo} style={{width:750, height:450, position:'absolute', left:(width/2)-300, top:(height/2)-225}} />
            <BlurView intensity={90} tint={"light"}
                style={{padding:100, borderRadius:20, margin:'auto', alignItems:'center', justifyContent:'center', width:"100%"}} >
                <Text style={{fontSize:20}}><Text style={{fontWeight:'bold'}}>Cambio de contraseña{"\n"}</Text> Por favor, ingrese la nueva contraseña para su cuenta:{"\n"}{"\n"}</Text>
                <TextInput
                    onChangeText={txt => setPassword1(txt)}
                    placeholder="*****"
                    style={{ marginTop: 20, width:250 }} //give custom styles
                />
                                <Text style={{fontSize:20}}>Repite la misma contraseña: {"\n"}{"\n"}</Text>
                 <TextInput
                    onChangeText={txt => setPassword2(txt)}
                    placeholder="*****"
                    style={{ marginTop: 20, width:250 }} //give custom styles
                />
                <Text onPress={() => handleSubmit()} style={{color:'#3e5ba6'}}>Cambiar contraseña {'->'}</Text>
            </BlurView>
            </>
            )
        }
    }else{
        return(
        <>
        <Image source={logo} style={{width:750, height:450, position:'absolute', left:(width/2)-300, top:(height/2)-225}} />
        <BlurView intensity={90} tint={"light"}
            style={{padding:100, borderRadius:20, margin:'auto', alignItems:'center', justifyContent:'center', width:"100%"}} >
            <Text style={{fontSize:20}}>La autentificación no es válida. Utilice el enlace que ha recibido en su correo electrónico o solicite otro cambio de contraseña.{"\n"}{"\n"}</Text>
            <Text onPress={() => Linking.openURL('/')} style={{color:'#3e5ba6'}}>Volver al inicio {'->'}</Text>
        </BlurView>
        </>
        )
    }

}
export default Passwordreset