import { useStoreState } from 'easy-peasy'
import React from 'react'
import { View, Text, useWindowDimensions } from 'react-native'
import { Chat } from '../../components/Chat'

const ChatTherapistScreen = ({route}) => {
    const userpk = useStoreState(state => state.userpk);
    const {width} = useWindowDimensions()
    const patient = route.params.d.pk  // Vamos a necesitar "d.pk" para invocarle a chat desde therapist
    console.log(patient)
    return (
            <View  style={{flex:1, margin:"auto", width: width>768?width*0.8:"100%"}}>
                 <Chat userpk ={userpk} recipient = {patient}/>
            </View>
    )
}

export default ChatTherapistScreen


/// Vamos a cmabiar Example por chat y eliminar el old. Luego hacer un marco arrribva con ela vatar y el nombre en grande y pasar por props el link y nombre para abajo.