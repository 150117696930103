import React, { useEffect, useState, useCallback } from "react"
import { useIsFocused, useNavigation } from '@react-navigation/native';
import axios from "axios"
import { action, useStoreActions, useStoreState } from "easy-peasy"
import { API_URL } from "../core/api"
import { View, Text, ScrollView, StyleSheet, Dimensions, TouchableOpacity } from "react-native"
import { Avatar } from 'react-native-elements/';

const { width } = Dimensions.get('window');

var dataPatients = []
const ListPatients = () => {
    console.log('listpatients')
    const Config = useStoreState(state => state.AuthConfig)
    ///const [dataPatients, setDataPatients] = useState([])
    const setData  = useStoreActions(action => action.setData)
    const navigation = useNavigation()
    console.log('LIST NAVVVVV')

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    
    function getPatients (){
        console.log('Collecting data patients')
        axios.get(API_URL+'therapylink/', Config)
        .then(res =>{
            console.log('resLIST', res.data),
            // setDataPatients(res.data)
            res.data.map((d, i) => (
                axios.get(API_URL+'user/'+d.user_id+'/userdetails/', Config)
                .then(res2 => {
                    //console.log(res2.data)
                    res2.data.therapylink = d
                    //console.log( 'ress0', res2.data)
                    dataPatients.push(res2.data)
                        const payload = {}
                        payload.dataPatients = dataPatients
                        // payload.dataPatients.therapylink = d
                        // console.log('LOGGG', payload)
                        // payload.dataPatients
                        res.data.length == i+1 ? setData(payload):null    
                    forceUpdate()
                    
                }) 

            ))
        })
    }
    
    const isFocused = useIsFocused()
    useEffect(() => {
       if (isFocused) {
        dataPatients = [],
       getPatients()
       //console.log('DATAAAAAAA PAAAATIEEENT EFFECT')
       }  
    }, [isFocused])

   

    // const listItems = dataPatients.map((d,i) =>
    // ( <TouchableOpacity onPress={() => navigation.navigate('PatientTherapistStackScreen', {d})}>
    // <View style={[styles.view,{padding:20, alignItems:'center', justifyContent:'center', alignContent:'center'}]} >
           
    //         <Avatar source={ { uri: d.avatar}} rounded={true} size={100} />
    //      <Text style={{paddin:5}}>{"\n"}{d.name}</Text>
    //      {d.therapylink.accepted ? null : (<Text style={{color:'white', fontWeight:'bold', backgroundColor:'#3e5ba6', padding:5, borderRadius:10}}>¡Nuevo!</Text>)}
    //      </View>
    //      </TouchableOpacity>)
    // )

    const listNewItems = dataPatients.map((d,i) =>
    ( <>{d.therapylink.accepted ? null : (<TouchableOpacity onPress={() => navigation.navigate('PatientTherapistStackScreen', {d})}>
    <View style={[styles.view,{padding:20, alignItems:'center', justifyContent:'center', alignContent:'center'}]} >
           
            <Avatar source={ { uri: d.avatar}} rounded={true} size={100} />
         <Text style={{paddin:5}}>{"\n"}{d.name}</Text>
         <Text style={{color:'white', fontWeight:'bold', backgroundColor:'#3e5ba6', padding:5, borderRadius:10}}>¡Nuevo!</Text>
         </View>
         </TouchableOpacity>)}
    </>)
    )
    const listPreviousItems = dataPatients.map((d,i) =>
    ( <>{d.therapylink.accepted ?(<TouchableOpacity onPress={() => navigation.navigate('PatientTherapistStackScreen', {d})}>
    <View style={[styles.view,{padding:20, alignItems:'center', justifyContent:'center', alignContent:'center'}]} >
           
            <Avatar source={ { uri: d.avatar}} rounded={true} size={100} />
         <Text style={{paddin:5}}>{"\n"}{d.name}</Text>
         </View>
         </TouchableOpacity>) : null }
    </>)
    )

    console.log(dataPatients)
    return (<View>
        
        <ScrollView horizontal>
            {listNewItems}{listPreviousItems}
        </ScrollView>
        </View>)

}

const styles = StyleSheet.create({
    container: {},
    view: {
    //   marginTop: 100,
    //   backgroundColor: 'blue',
    //   width: width - 200,
    //   margin: 10,
    //   height: 200,
    //   borderRadius: 10,
      //paddingHorizontal : 30
     
        height:200,
        width: 250,
        margin : 15,
        backgroundColor:"white",
        borderRadius:15,
        elevation:10,
        padding:10
 
    },

  });

export default ListPatients 
