import { useIsFocused } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { ScrollView, StatusBar, Dimensions, Text, Platform } from 'react-native'
// import ScrollableTabView from 'react-native-scrollable-tab-view'
import {
  LineChart,
  BarChart,
  PieChart,
  ProgressChart,
  ContributionGraph
} from 'react-native-chart-kit'
//import { data, contributionData, pieChartData, progressChartData } from './data'
// import 'babel-polyfill'
import axios from 'axios'
import { API_URL } from '../core/api'
import { useStoreState } from 'easy-peasy'

// const data = {
//     labels: ['17 Jun', '22 Jun', '26 Jun', '30 Jun', '1 Jul', '2 Jul'],
//     datasets: [
//         { data: [0],
//             color: (opacity = 0) => `transparent`, // optional
//         }, 
//         { data: [5],
//             color: (opacity = 0) => `transparent`, // optional
//         }, {
//       data: [
//         2,
//         2,
//         1,
//         4,
//         3,
//         5
//       ],
//       color: (opacity = 1) => `rgba(134, 65, 244, ${opacity})`, // optional
//       strokeWidth: 8 // optional
//     },{
//       data: [
//         2,
//         1,
//         4,
//         5,
//         4,
//         3
//       ],
//       color: (opacity = 1) => `rgba(13, 105, 244, ${opacity})`,
//       strokeWidth: 8 // optional // optional
//     },{
//       data: [
//         3,
//         3,
//         4,
//         5,
//         1,
//         2
//       ],
//       color: (opacity = 1) => `rgba(134, 165, 24, ${opacity})`,
//       strokeWidth: 8 // optional // optional
//     }]
//   }


class ChartGraph extends React.Component {
  renderTabBar() {
    return <StatusBar hidden/>
  }
  render() {
    var width = Dimensions.get('window').width
    width = Platform.OS==='web'?width*0.8:width
    const height = Platform.OS==='web'?220:160


  const chartConfig = {
    // backgroundColor: 'transparent',
    backgroundGradientFrom: '#FFF',
    backgroundGradientTo: '#FFF',
    color: (opacity = 0.3) => `rgba(26, 25, 146, ${opacity})`,
  }

    const labelStyle = {
        color: chartConfig.color(), //'black'
        marginVertical: 10,
        textAlign: 'center',
        fontSize: 16
      }
      const graphStyle = {
        marginVertical: 8,
        color : 'green',
        // ...chartConfig.style
      }

      function* yLabel() {
        yield* ['0', '', '', '', '5'];
      }
      const yLabelIterator = yLabel();

    return (<>
    

            <ScrollView
              key={Math.random()}
              style={{
                // backgroundColor: 'white'
              }}
            >
              <Text style={labelStyle}>Progreso en sesiones</Text>
              <LineChart
                //data={data}
                data = {this.props.data}
                width={width}
                formatYLabel={() => yLabelIterator.next().value}
                height={height}
                chartConfig={chartConfig}
                bezier
                fromZero
                //withDots={true}
                yAxisInterval={5}
                // yLabelsOffset={3}
                style={graphStyle}
              />
            <Text style={{marginHorizontal: 'auto'}}>
            <Text style = {{color: `#F43E3E`}}>   Valencia emocional   </Text>
            <Text style = {{color: `#96BC5C`}}>   Arousal o activación   </Text>
            <Text style = {{color: `#AB7854`}}>   Dominancia   </Text>
            </Text>
            </ScrollView>
          


      </>
    )
  }
}


const Chart = (props) => {
  const isFocused = useIsFocused()
  const Config = useStoreState(state => state.AuthConfig)
  const [busy, setBusy] = useState(true)

  console.log('props id', props.patient_pk)

  const dataTemplate = {labels:['','','','','',''], datasets: [{data:[0], color: (opacity = 0) => `transparent`}, {data:[5], color: (opacity = 0) => `transparent`} ]}

  const [chartData, setChartData] = useState(dataTemplate)

  useEffect( () => {
    console.log('comienza chart')
    setBusy(true);
    const GetPrev6meassures = async () => {
      // ya funciona axios, ahora meter datos en formato apropiado!

      await axios.get(API_URL+'appointments/'+ props.patient_pk +'/listbyuser/', Config)
        .then(res => {
          var last6 = res.data.slice(-6);
          console.log('lastt6', last6)

          var labels = [];

          var dataPL = [];
          var dataAR = [];
          var dataDM = [];
          const options = {  month: 'long', day: 'numeric' };
          for (var i = 0, max = last6.length; i < max; i += 1) {
            labels.push(new Date(res.data[i].appointment_at).toLocaleDateString('es-ES', options))
            dataPL.push(last6[i].pleasure)
            dataAR.push(last6[i].arousal)
            dataDM.push(last6[i].dominance)
            console.log('item valor', last6[i].dominance)
          }//console.log(labels)
          console.log('tras bucle', last6)
          const data = {labels:labels, datasets: [{data:[0], color: (opacity = 0) => `transparent`}, {data:[5], color: (opacity = 0) => `transparent`}, {data:dataPL, color: (opacity = 1) => `#F43E3E`, strokeWidth: 8}, {data:dataAR, color: (opacity = 1) => `#96BC5C`, strokeWidth: 8 }, {data:dataDM, color: (opacity = 1) => `#AB7854`, strokeWidth: 8 } ]}
          setChartData(data)
          setBusy(false)
          console.log('builddchartt', chartData)
        })
        .catch( error => {
          console.log(error)
        })
      };

    GetPrev6meassures (); console.log('buildd', chartData)

}
  , [isFocused])
  return(<>
    {busy?(<Text>Cargando progreso </Text>):(<ChartGraph data = {chartData} />)}
  </>)
}
export default Chart