import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useStoreState } from 'easy-peasy';
import React, { useContext, useEffect } from 'react'
import { View, Text, StyleSheet, ScrollView, FlatList, Dimensions, ListView, ListViewBase } from 'react-native'
// import { Calendar, CalendarList, ExpandableCalendar, Timeline, WeekCalendar } from 'react-native-calendars';
import { Avatar } from 'react-native-elements';
import  SetAppointment  from '../../components/SetAppointment';
import ExpandableCalendarScreen from '../../components/CalendarPatient';

const {width} = Dimensions.get('window')

const PatientCalendarTherapistScreen = (props) => {
  const data = useStoreState(state => state.selectedPatient)
  const Config = useStoreState(state => state.AuthConfig);
  const navigation = useNavigation()
  const isFocused = useIsFocused()

  useEffect(() => {}, [isFocused])
console.log(isFocused)
    return (
        <View style={{alignItems:'center'}}>
         <ExpandableCalendarScreen userpk = {data.therapylink.user_id} navigation = {navigation} Config = {Config} />
        </View>  
    )
}

const styles = StyleSheet.create({

});
export default PatientCalendarTherapistScreen
