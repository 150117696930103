import 'react-native-gesture-handler';
import React, { useState, useEffect } from 'react';
import {
  TouchableOpacity, View, Text, StyleSheet, Button
} from 'react-native';
import { createStore, StoreProvider, useStoreState, useStoreActions } from 'easy-peasy';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';
import { Icon } from 'react-native-elements';
import ProfileStackScreen from '../Profile';
import DashPatientStackScreen from './DashPatient';
import CalendarPatientStackScreen from './CalendarPatient';
import ChatPatientStackScreen from './ChatPatient';
import NewEventRecordPatientStackScreen from './NewEventRecordPatient';
import axios from 'axios';
import { API_URL } from '../../../core/api';
import AvatarDrawer from '../../../components/AvatarDrawer';

function DrawerContent({navigation}) {
    const therapistAccepted = useStoreState(state => state.therapistAccepted)
    // Aquí se chequea si tiene un terapeuta aceptado para mostrar el resto.
    const signOut = useStoreActions((action) => action.signOut);
    const handleSignOut = () => {
      signOut();
    }

  const Config = useStoreState(state => state.AuthConfig)
  const setData = useStoreActions(action => action.setData)
  const CheckNotificacion = () => {
      axios.get(API_URL+'chat/notification', Config)
      .then(res => {
        let payload = {}
        payload.notifications = res.data
        setData(payload)
        console.log('Checking for notifications')
      })
      .catch( error => console.log(error))
  }

  useEffect(() => CheckNotificacion())
    
    return (
      <DrawerContentScrollView style={{flexGrow:1, flexDirection: 'column' }}>

        <View style={styles.userInfoSection}>
          <TouchableOpacity  style={{padding: 10, flexDirection:"row"}} 
              onPress={() => handleSignOut()}>
              <Text style={{color:"#666666"}}>Cerrar sesión </Text>
              <Icon name="poweroff" type="antdesign" size={15} color={"#666666"}/>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => navigation.navigate('ProfileStackScreen')}>
            <AvatarDrawer />
          </TouchableOpacity>
        </View>

          <DrawerItem
            label="Inicio"
            onPress={() => { navigation.navigate('DashPatientStackScreen'); }}
          />
          <DrawerItem
            label="Registrar evento"
            onPress={() => { navigation.navigate('NewEventRecordPatientStackScreen'); }}
          />
  
  {therapistAccepted ? (<View>
          <DrawerItem
            // icon={({ color, size }) => (
            //   <MaterialCommunityIcons  name="tune" color={color} size={size} />
            // )}
            label="Citas"
            onPress={() => { navigation.navigate('CalendarPatientStackScreen'); }}
          />
          <DrawerItem
            label="Chat"
            onPress={() => { navigation.navigate('ChatPatientStackScreen'); }}
          />
          </View>) : null }

          {/* <TouchableOpacity 
              style={{
                paddingHorizontal: 10, flexDirection:"row"
              }}
              onPress={() => handleSignOut()}
            >
              <Text>Cerrar sesión </Text>
              <Ionicons
                  name="ios-redo"
                  size={20}
                  color={"#000"}
              />
          </TouchableOpacity> */}
          
    </DrawerContentScrollView>
    );
  }
  
  
  const styles = StyleSheet.create({
    drawerContent: {
      //flex: 1,
    },
    userInfoSection: {
      flex:1
      //paddingLeft: 20,
    },
    title: {
      marginTop: 20,
      fontWeight: 'bold',
    },
    caption: {
      fontSize: 14,
      lineHeight: 14,
    },
    row: {
      marginTop: 20,
      flexDirection: 'row',
      alignItems: 'center',
    },
    section: {
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: 15,
    },
    paragraph: {
      fontWeight: 'bold',
      marginRight: 3,
    },
    drawerSection: {
      marginTop: 15,
    },
    preference: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingVertical: 12,
      paddingHorizontal: 16,
    },
  });
  
  
  

  const DrawerStack = createDrawerNavigator(); // Here comes the left stack list of links in the drawer
const DrawerPatientStackScreen = () => {             // Haty que crear un stack adhoc para cada uno de los que aquí creemos. 
  return ( 
    <DrawerStack.Navigator 
    screenOptions={{
      headerShown: false
    }} // ADDED to prevent double bar // add on ReactUpgrade
    drawerContent={props => <DrawerContent {...props} />}>
     
      <DrawerStack.Screen
          name={"DashPatientStackScreen"}
          component={DashPatientStackScreen}
      />
      <DrawerStack.Screen
          name={"ProfileStackScreen"}
          component={ProfileStackScreen}
      />
      <DrawerStack.Screen
          name={"CalendarPatientStackScreen"}
          component={CalendarPatientStackScreen}
      />
      <DrawerStack.Screen
          name={"ChatPatientStackScreen"}
          component={ChatPatientStackScreen}
      />
      <DrawerStack.Screen
          name={"NewEventRecordPatientStackScreen"}
          component={NewEventRecordPatientStackScreen}
      />
    </DrawerStack.Navigator>
  )
}

export default DrawerPatientStackScreen;