import React from 'react';
import { useStoreActions } from 'easy-peasy';
import { createStackNavigator } from "@react-navigation/stack";
import PatientTherapistScreen from '../../therapist/PatientTherapistScreen';
import AppointmentTherapistScreen from '../../therapist/AppointmentTherapistScreen';
import RecordTherapistScreen from '../../therapist/RecordTherapistScreen';
import PatientCalendarTherapistScreen from '../../therapist/PatientCalendarTherapistScreen';
import NotificationChatTherapist from '../../../components/NotificationChatTherapist';
import ChatTherapistScreen from '../../therapist/ChatTherapistScreen';
import AvatarNavHeader from '../../../components/AvatarNavHeader';

const PatientTherapistStack = createStackNavigator();
const PatientTherapistStackScreen = ({ navigation, route }) => {
  const data = route.params.d
  const setData = useStoreActions(action => action.setData)
  const payload = {}
  payload.selectedPatient = data
  setData(payload)

  // const PatientContext = createContext(data);

  // navigation.setParams('PatientTherapistScreen', data)
  return(
    <PatientTherapistStack.Navigator>
      <PatientTherapistStack.Screen
        name={"PatientTherapistScreen"}
        component={PatientTherapistScreen}
        //initialParams={data}
        options={{
          title: data.name,
          headerLeft : () => {
            return <AvatarNavHeader />
          },
          headerRight : () => {
            return (
              <NotificationChatTherapist />
            )
          },
        }}
      />
      <PatientTherapistStack.Screen
        name={"PatientCalendarTherapistScreen"}
        component={PatientCalendarTherapistScreen}
        options={{
          title: data.name,
        }}
      />
      <PatientTherapistStack.Screen  // Este es igual que el de Patient, para los events del PatientCalendar 
        name={"AppointmentPatientScreen"}
        component={AppointmentTherapistScreen}
        options={{
          title: data.name,
        }}
      />
      <PatientTherapistStack.Screen  // Este es igual que el de Patient, para los events del PatientCalendar 
      name={"RecordPatientScreen"}
      component={RecordTherapistScreen}
      options={{
        title: data.name,
      }}
    />
      <PatientTherapistStack.Screen  // Este es igual que el de Patient, para los events del PatientCalendar 
        name={"ChatPatientTherapistScreen"}
        component={ChatTherapistScreen}
        options={{
          title: data.name,
        }}
      />
      
    {/* <EveeentsTherapistStack.Screen
        name={"EventTherapistScreen"}
        component={EventTherapistScreen}
        options={{
          title: "Editar perfil",
        }}
    /> */}
      {/* <PatientTherapistStack.Screen
        name={"Detalles de Patient Screen"}
        component={Detalles de Patient Screen}
        options={{
          title: "Editar perfil",
        }}
    /> */}
    </PatientTherapistStack.Navigator>
  )
}
export default PatientTherapistStackScreen