import { useNavigation } from '@react-navigation/native';
import { useStoreState } from 'easy-peasy';
import React from 'react'
import { View, Text, StyleSheet, useWindowDimensions } from 'react-native'
//import { Calendar, CalendarList, ExpandableCalendar, Timeline, WeekCalendar } from 'react-native-calendars';
import AgendaTherapist from '../../components/AgendaTherapist.js';
import ExpandableCalendarScreen from '../../components/CalendarPatient.js';
//import { Agenda } from 'react-native-calendars';


const CalendarTherapistScreen = () => {
  const userpk = useStoreState( state => state.userpk)
  const Config = useStoreState(state => state.AuthConfig);
  const navigation = useNavigation()
  const {width} = useWindowDimensions();

    return (<View style={{flex:1, width: width>768?"80%":"100%", marginHorizontal:'auto'}}>
      <AgendaTherapist />
    </View>)
}

export default CalendarTherapistScreen



// const styles = StyleSheet.create({
//     calendar: {
//       marginBottom: 10
//     },
//     switchContainer: {
//       flexDirection: 'row',
//       margin: 10,
//       alignItems: 'center'
//     },
//     switchText: {
//       margin: 10,
//       fontSize: 16
//     },
//     text: {
//       textAlign: 'center',
//       padding: 10,
//       backgroundColor: 'lightgrey',
//       fontSize: 16
//     },
//     disabledText: {
//       color: 'grey'
//     },
//     defaultText: {
//       color: 'purple'
//     },
//     customCalendar: {
//       height: 250,
//       borderBottomWidth: 1,
//       borderBottomColor: 'lightgrey'
//     },
//     customDay: {
//       textAlign: 'center'
//     },
//     customHeader: {
//       backgroundColor: '#FCC',
//       flexDirection: 'row',
//       justifyContent: 'space-around',
//       marginHorizontal: -4,
//       padding: 8
//     },
//     customTitleContainer: {
//       flexDirection: 'row', 
//       alignItems: 'center', 
//       padding: 10
//     },
//     customTitle: {
//       fontSize: 16,
//       fontWeight: 'bold',
//       color: '#00BBF2'
//     }
//   });