import { useStoreState } from 'easy-peasy'
import React from 'react'
import { Dimensions, View, useWindowDimensions } from 'react-native'
import NewEventRecord from '../../components/NewEventRecord'

// const {width} = Dimensions.get('window')

const NewEventRecordPatientScreen = () => {
    const {width} = useWindowDimensions();
    const userpk = useStoreState(state => state.userpk);
    const therapistPk = useStoreState(state => state.therapistPk)
    return (
            <View  style={{flex:1, margin:"auto", width: width>768?width*0.8:"100%"}}>
                <NewEventRecord />
                {console.log('Event record loaded')}
            </View>
    )
}

export default NewEventRecordPatientScreen


/// Vamos a cmabiar Example por chat y eliminar el old. Luego hacer un marco arrribva con ela vatar y el nombre en grande y pasar por props el link y nombre para abajo.