
//import 'react-native-gesture-handler';
import React, { useState, useEffect } from 'react';
import {
  StatusBar, Settings, TouchableOpacity, View, Text, StyleSheet, Button
} from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createStore, StoreProvider, useStoreState, useStoreActions } from 'easy-peasy';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';
///// import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Ionicons from 'react-native-vector-icons/Ionicons'
// import ProfileStackScreen from './src/screens/navigation/Profile';
// import DashPatientStackScreen from './src/screens/navigation/Patient/DashPatient';
// import CalendarPatientStackScreen from './src/screens/navigation/Patient/CalendarPatient';
// import ChatPatientStackScreen from './src/screens/navigation/Patient/ChatPatient';
import DrawerPatientStackScreen from './src/screens/navigation/Patient/DrawerPatient';
import DrawerTherapistStackScreen from './src/screens/navigation/Therapist/DrawerTherapist';



/** Screens */
import WelcomeScreen from './src/screens/WelcomeScreen';
import ProfileScreen from './src/screens/profile/ProfileScreen';
import LoginScreen from './src/screens/LoginScreen';
import SignUpScreen from './src/screens/SignUpScreen';
import SignUpScreenTherapist from './src/screens/SignUpScreenTherapist';
import SignUpScreenUser from './src/screens/SignUpScreenUser';


import STORE from './src/Store/model';
import DashPatientScreen from './src/screens/patient/DashPatientScreen';
import CalendarPatientScreen from './src/screens/patient/CalendarPatientScreen';
import ChatPatientScreen from './src/screens/patient/ChatPatientScreen';
import { set } from 'react-native-reanimated';
import AvatarDrawer from './src/components/AvatarDrawer';
import LandingPage from './src/web/pages/LandingPage';

// let initialStateStore = {}
const store = createStore(STORE);
// initialStateStore = store.getState();


const AuthStack = createStackNavigator();
const AuthenticationStack = (props) => {
  console.log(props)
  return (
    <AuthStack.Navigator
    headerMode="none" // Esto quita las barras de arriba
      {...props}
    >
      <AuthStack.Screen
          name={"Bienvenido a Psykehub"}
          component={LandingPage}
      />
      <AuthStack.Screen
        name={"Authentication"}
        component={WelcomeScreen}
        options={{
          // title: "Welcome"
        }}
      />
      <AuthStack.Screen
        name={"SignIn"}
        component={LoginScreen}
      />
      <AuthStack.Screen
          name={"SignUp"}
          component={SignUpScreen}
      />
      <AuthStack.Screen
          name={"SignUpTherapist"}
          component={SignUpScreenTherapist}
      />
      <AuthStack.Screen
          name={"SignUpUser"}
          component={SignUpScreenUser}
      />
    </AuthStack.Navigator>
  )
}


const RootStack = createStackNavigator();
const RootStackScreen = (props) => {
//   const [selectedScreen, setSelectedScreen] = useState(<RootStack.Screen
//     name={"Bienvenido"}
//     component={AuthenticationStack}
//     options={{
//       animationEnabled: false
//     }}
//   />)
  const isloggedin = useStoreState(state => state.isLoggedin);
  const typeuser = useStoreState(state => state.typeuser);
  
  const loggin = useStoreActions(action => action.loggin)
  const setAuthdata = useStoreActions(action => action.setAuthdata)
  var token
  const checkAuthData = async () => {
    try{
      token = await AsyncStorage.getItem("token");  /////  Aqui hay movida, comprobamos con respecto al ASYNCPERSISTANT si hay token para entrar directamente
      
      if (token){
        const userpk = await AsyncStorage.getItem("userpk");
        const username = await AsyncStorage.getItem("username");
        const name = await AsyncStorage.getItem("name");
        const avatar = await AsyncStorage.getItem("avatar");
        const is_user = await AsyncStorage.getItem("is_user");
        const is_therapist = await AsyncStorage.getItem("is_therapist");
        const status = await AsyncStorage.getItem("status");
        const bio = await AsyncStorage.getItem("bio");
        setAuthdata({userpk, username, name, avatar, is_user, is_therapist, status, bio, token})
        loggin(true)
        
        // Decide what screen if PATIENT or THERAPIST
        // switch('true'){ // En este caso, se guardó como "true", no true
        //   case is_user:
        //     setSelectedScreen(<RootStack.Screen
        //           name={"Dashboard"}
        //           component={DrawerPatientStackScreen}
        //           // initialParams={{'token':token}}
        //           options={{
        //             title: "Feed"
        //           }}
        //       />)
        //       break
    
        //   case is_therapist:
        //     setSelectedScreen(<RootStack.Screen
        //           name={"Dashboard"}
        //           component={DrawerTherapistStackScreen}
        //           // initialParams={{'token':token}}
        //           options={{
        //             title: "Feed"
        //           }}
        //       />)
        //       break
        // }


        // switch('true'){
        //   case is_user, isloggedin:
        //     console.log('useeeeeeeeeeeeer');
        //     break
        //   case is_therapist:
        //     console.log('therapiiiiiiiiiiiiiiist');
        //     break
        // }

      }
    }catch(error){

    }

 
  }
  
  
  // function ScreenSelection () {
  //   console.log(is_user, 'q pasa')
  //   switch('true'){
  //     case is_user:
  //       setSelectedScreen(<RootStack.Screen
  //             name={"Dashboard"}
  //             component={DrawerPatientStackScreen}
  //             // initialParams={{'token':token}}
  //             options={{
  //               title: "Feed"
  //             }}
  //         />)
  //         break

  //     case is_therapist:
  //       setSelectedScreen(<RootStack.Screen
  //             name={"Dashboard"}
  //             component={DrawerPatientStackScreen}
  //             // initialParams={{'token':token}}
  //             options={{
  //               title: "Feed"
  //             }}
  //         />)
  //         break
  //   }
  // }
  
  useEffect(async () => {
    //checkToken()
    checkAuthData(),
    // ScreenSelection(),
    console.log(isloggedin, 'checking token')
  }, []);
  
  console.log(token, ' Muestra si hay token')

// console.log('cuandoo')
// switch (typeuser){
//   case "patient":
//     return (
//       <RootStack.Navigator 
//       headerMode="none"
//       {...props}
//       >
//         <RootStack.Screen
//         name={"Dashboard"}
//         component={DrawerPatientStackScreen}
//         // initialParams={{'token':token}}
//         options={{
//           title: "Feed"
//         }}
//         />
      
//     </RootStack.Navigator>)

//   case "therapist":
//     return (
//       <RootStack.Navigator 
//       headerMode="none"
//       {...props}
//       >
//         <RootStack.Screen
//         name={"Dashboard"}
//         component={DrawerTherapistStackScreen}
//         // initialParams={{'token':token}}
//         options={{
//           title: "Feed"
//         }}
//         />
      
//     </RootStack.Navigator>)
  
//   default: 
//   return (
//     <RootStack.Navigator 
//     headerMode="none"
//     {...props}
//     >
//       <RootStack.Screen
//       name={"Dashboard"}
//       component={AuthenticationStack}
//       // initialParams={{'token':token}}
//       />
    
//   </RootStack.Navigator>)

// }


return (
  <RootStack.Navigator 
  headerMode="none"
  {...props}
  >
    {/* <RootStack.Screen
    name={"Dashboard"}
    component={DrawerPatientStackScreen}
    // initialParams={{'token':token}}
    options={{
      title: "Feed"
    }}
    /> */}
    {(() => {
      switch(typeuser){
        case "patient":
          return <RootStack.Screen
          name={"Dashboard"}
          component={DrawerPatientStackScreen}
          // initialParams={{'token':token}}
          options={{
            title: "Feed"
          }}
          />
        case "therapist":
          return <RootStack.Screen
          name={"Dashboard"}
          component={DrawerTherapistStackScreen}
          // initialParams={{'token':token}}
          options={{
            title: "Feed"
          }}
          />
        default:
          return <RootStack.Screen
          name={"Dashboard"}
          component={AuthenticationStack}
          />
      }
    })()}
  {console.log('PRUEBA LOG', isloggedin)}
  {console.log('PRUEBA LOG2', typeuser)}
</RootStack.Navigator>)
  
}

const App = () => {
  return (
    <SafeAreaProvider>
    <StoreProvider store={store}>
        <NavigationContainer>
          <StatusBar barStyle="dark-content" />
          {console.log('WEEEEEEB')}
          <RootStackScreen />
      </NavigationContainer>
    </StoreProvider>
    </SafeAreaProvider>
  );
};

export default App;
