import React, { useEffect, useState, useCallback } from "react"
import {   Button, View, Text, ScrollView, StyleSheet, Dimensions, TouchableOpacity } from "react-native"
// import DatePicker from 'react-native-date-picker'

// import DateTimePicker from '@react-native-community/datetimepicker'; 

// import DateTimePicker from 'react-native-modal-datetime-picker';
// import DatePicker from "react-native-date-picker-select";
// import DateTimePickerModal from "react-native-modal-datetime-picker";


import moment from 'moment';
import 'moment/locale/es';
import axios from "axios";
import { API_URL } from "../core/api";
import { useNavigation } from "@react-navigation/native";
import { useStoreState } from "easy-peasy";

import DatePicker from "react-multi-date-picker"
import TimePicker from "react-multi-date-picker/plugins/time_picker"
import ButtonCalendar from "react-multi-date-picker/components/button"

const SetAppointment = (props) => {
  
    const [date, setDate] = useState(new Date())
    const [open, setOpen] = useState(false)
    const [selectedAppoitnment, setSelectedAppointment] = useState(false)
    const navigation = useNavigation()
    const Config = useStoreState(state => state.AuthConfig)

    const submitAppointment = () => {
      const newAppointment = {
        'user_id': props.user_id,
        'therapist_id': props.therapist_id,
        'appointment_at': new Date(date).toJSON(),
      } 
      console.log(newAppointment, 'ERROR API')
      axios.post(API_URL+'appointments/create/', newAppointment, Config)
      .then(
        setSelectedAppointment(false)
      ).catch(
        error => console.log(error)
      )
    }

    const gregorian_es_lowercase = {
      name: "gregorian_es_lowercase",
      months: [
        ["enero", "ene"],
        ["febrero", "feb"],
        ["marzo", "mar"],
        ["abril", "abr"],
        ["mayo", "may"],
        ["junio", "jun"],
        ["julio", "jul"],
        ["agosto", "ago"],
        ["septiembre", "sep"],
        ["octubre", "oct"],
        ["noviembre", "nov"],
        ["diciembre", "dic"],
      ],
      weekDays: [
        ["sábado", "sáb"],
        ["domingo", "dom"],
        ["lunes", "lun"],
        ["martes", "mar"],
        ["miércoles", "mié"],
        ["jueves", "jue"],
        ["viernes", "vie"],
      ],
      digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
      meridiems: [
        ["AM", "am"],
        ["PM", "pm"],
      ],
    };

    return(
        <>
<View style={{flexDirection:'row', justifyContent:'center'}}>
<DatePicker 
        format="MM/DD/YYYY HH:mm:ss"
        plugins={[
          <TimePicker position="bottom" />
        ]} 
        // render={<ButtonCalendar />}
        render={(value, openCalendar) => {
          return (
            <TouchableOpacity onPress={openCalendar} > 
              <View style={{ backgroundColor: '#3e5ba6', borderRadius: 30, margin: 5, flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding : 10 }}>
                  {selectedAppoitnment?(<Text style={{color:"white", fontSize:16, fontWeight:"bold"}}>Cambiar</Text>):(<Text style={{color:"white", fontSize:16, fontWeight:"bold"}}>Fijar cita</Text>)}
              </View>
            </TouchableOpacity>
          )}}
        value = {null}
        onChange = {(datepicked) => {setDate(new Date(datepicked)), setSelectedAppointment(true)}}
        locale={gregorian_es_lowercase}
        
      />

    {selectedAppoitnment?null:(<TouchableOpacity onPress={() => navigation.navigate('PatientCalendarTherapistScreen')} > 
      <View style={{ backgroundColor: '#3e5ba6', borderRadius: 30, margin: 5, flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding : 10 }}>
          <Text style={{color:"white", fontSize:16, fontWeight:"bold"}}>Registro del paciente</Text>
      </View>
    </TouchableOpacity>)}


  {/* <DatePicker
        modal
        open={open}
        date={date}
        onConfirm={(date) => {
          setOpen(false)
          setDate(date)
          setSelectedAppointment(true)
        }}
        onCancel={() => {
          setOpen(false)
        }}
        locale="es-ES"
        //androidVariant="iosClone"
        minuteInterval={5}
        is24hourSource="locale"
      /> */}
    
    {/* <Button title="Show Date Picker" onPress={showDatePicker} /> */}
    {/* <DateTimePickerModal
        isVisible={open}
        mode="datetime"
        date={date}
        onConfirm = {(date) => {
          setOpen(false)
          setDate(date)
          setSelectedAppointment(true)}}
        onCancel={() => {
          setOpen(false)}}
      /> */}

      

        {selectedAppoitnment?(


        <View style={{flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
          <Text>{moment(date).local().format('DD MMM [a las] hh:mm A')}</Text>
     
          {/* <Button style={{backgroundcolor:'blue'}} title="Fijar cita" onPress={() => setOpen(true)} />  */}
          <TouchableOpacity onPress={() => submitAppointment()} > 
            <View style={{ backgroundColor: 'green', borderRadius: 30, margin: 2, flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding : 10 }}>
                <Text style={{color:"white", fontSize:12, fontWeight:"bold"}}>Confirmar</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setSelectedAppointment(false)} > 
            <View style={{ backgroundColor: 'red', borderRadius: 30, margin: 2, flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding : 10 }}>
                <Text style={{color:"white", fontSize:12, fontWeight:"bold"}}>Cancelar</Text>
            </View>
          </TouchableOpacity>
        </View>): null }
</View>

        </>
    )

}


export default SetAppointment

