import React, { useEffect, useState } from 'react'
import { View, Text, StyleSheet, Image, Animated } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'

import AsyncStorage from '@react-native-async-storage/async-storage';

// import AvatarDrawer from '../components/AvatarDrawer';
// import { Transition } from 'react-native-reanimated';

import logo from '../../assets/LogoPSYKEHUBlowr.png'
import bandas from '../../assets/bandasLogo.png'
import bandasR from '../../assets/bandasLogoR.png'
import { useStoreState } from 'easy-peasy';

const styles = StyleSheet.create({
    text: {
      fontSize: 35,
      paddingVertical: 50,
      fontFamily: 'Gill Sans',
      fontWeight: 'bold',
    },
  });

const WelcomeScreen = ({ navigation }) => {
    
    const [componente, setComponent] = useState(<Landingscreen/>)
    // const [springValue, setSpringValue] = useState(new Animated.Value(0.3))

    const transition = () => {
        setTimeout(() => {console.log('esperara 2 sec'), 
        setComponent(<Logregoptions navigation ={navigation} />)}, 2000); //2000
        console.log('espero ya?')
    }
    // const spring = () => {
    //     springValue.setValue(0.3)
    //     Animated.spring(
    //         springValue,
    //         {
    //             toValue: 1,
    //             friction: 1,
    //             // easing: Easing.linear
    //         }
    //     ).start()
    // }

    useEffect(() => {transition()}, []);
    
    const statee = useStoreState(state => state)
    console.log("En Welcome TODO el STORESTATE", statee)

    return(
        componente
    )
}

export default WelcomeScreen;


function Landingscreen(props){
    const [springValue, setSpringValue] = useState(new Animated.Value(0.3))

    function spring () {
        springValue.setValue(0.7)  // 0.3 example
        Animated.spring(
            springValue,
            {
                toValue: 1,
                friction: 1,
                // easing: Easing.linear
            }
        ).start()
    }
    useEffect(() => spring(), [])

    return(
        <View style={stylesLanding.container}>
            <View style={{flex:2}}>
                <Image source={bandasR} style={stylesLanding.imageBandasRight} />
            </View>
            <View style={stylesLanding.middleContainer}>
                {/* <Image source={logo} style={stylesLanding.imageLogo} /> */}
                <Animated.Image  style={[stylesLanding.imageLogo, 
                        {transform: [{scale: springValue}] }]}
                        source={logo} />
            </View>
             <View style={stylesLanding.topContainer}>
                <Text style={stylesLanding.h1}> PSYKE<Text style={{fontWeight:"bold"}}>HUB</Text></Text>
            </View>
            <View style={{flex:2}}>
                <Image source={bandas} style={stylesLanding.imageBandasLeft} />
            </View>
        </View>
    )
}

const stylesLanding = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
    //   alignItems: 'center',
    //   justifyContent: 'center',
    },
    h1: {
        color: '#3e5ba6',
        fontSize: 40,
      },
    imageLogo: {
        width: 300,
        height: 180,
        justifyContent: 'center',
      },
    imageBandasLeft: {
        width: 150,
        height: 100,
        bottom:10,
        position: "absolute",
    },
    imageBandasRight: {
        width: 150,
        height: 100,
        top:10,
        right:0,
        position: "absolute",
    },
    topContainer: {
        flex:1,
        justifyContent:'center',
        alignItems:'center',
      },
    middleContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }
  });


function Logregoptions(props){
    return (
        <View style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <View  style={{flex:0.5}}>
                <Image source={logo} style={stylesLanding.imageLogo} />
            </View>
            <TouchableOpacity style={{
                backgroundColor: '#3e5ba6',
                paddingHorizontal: 40, 
                borderRadius: 20,
                paddingVertical: 8,
                margin: 6
            }}
                onPress={() => {
                    props.navigation.navigate("SignIn")
                }}
            >
                <Text style={{
                    color: '#FFF',
                    fontSize: 20
                }}>
                    Entrar
                </Text>
            </TouchableOpacity>
            <TouchableOpacity style={{
                backgroundColor: '#FFF',
                paddingHorizontal: 40, 
                borderRadius: 20,
                paddingVertical: 8,
                margin: 6
            }}
                onPress={async () => {
                    props.navigation.navigate("SignUp")
                    console.log(await AsyncStorage.getAllKeys(), 'on presssing entrar') 
                }}
            >
                <Text style={{
                    color: '#3e5ba6',//'#834462', del logo    //'#dc307c', original 
                    fontSize: 20
                }}>
                    Registrarse
                </Text>
            </TouchableOpacity>
        </View>
    )
}