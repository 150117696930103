import React from 'react'
import { View, ScrollView, StyleSheet,Text, TouchableOpacity } from 'react-native'
import { Avatar } from 'react-native-elements/';
import User from '../../assets/icons/User.png'
import Therapist from '../../assets/icons/Therapist.png'
import { useNavigation } from '@react-navigation/native';

const SignUpScreen = () => {
  const navigation = useNavigation()
  return(<>
    <View style={{width:"100%", padding:'10%'}}>
        <Text style={{fontWeight:'bold', color:'#3e5ba6'}}>¡Bienvenido a Psykehub!</Text>
        <Text>Antes de darte de alta, selecciona un tipo de cuenta:{"\n"}{"\n"}</Text>
        <View style={{flexDirection:'row', alignItems:'center', margin: 'auto'}}>
        <ScrollView horizontal>
            <TouchableOpacity onPress={() => navigation.navigate('SignUpUser')}>
                <View style={[styles.view,{padding:20, alignItems:'center', justifyContent:'center', alignContent:'center'}]} >
                    
                        <Avatar source={User} rounded={true} size={100} />
                    <Text style={{color:'white', fontWeight:'bold', backgroundColor:'#3e5ba6', padding:5, borderRadius:10}}>Cuenta de Usuario</Text>
                    <Text style={{paddin:5}}>{"\n"}Te permite conectarte con tu terapeuta para ver el progreso de la terapia y llevar un registro de tus citas y eventos.</Text>
                </View>
            </TouchableOpacity>
            {/* <View style={{width:"50%", height:'60%'}}>
                <Text>Cuenta de Terapeuta</Text>
                <Text>Te permite tener una lista de tus pacientes para ver el progreso de sus terapia y llevar un registro de sus citas y eventos.</Text>
            </View> */}
                        <TouchableOpacity onPress={() => navigation.navigate('SignUpTherapist')}>
                <View style={[styles.view,{padding:20, alignItems:'center', justifyContent:'center', alignContent:'center'}]} >
                    
                        <Avatar source={Therapist} rounded={true} size={100} />
                    <Text style={{color:'white', fontWeight:'bold', backgroundColor:'#553ea6', padding:5, borderRadius:10}}>Cuenta de Terapeuta</Text>
                    <Text style={{paddin:5}}>{"\n"}Te permite tener una lista de tus pacientes para ver el progreso de sus terapia y llevar un registro de sus citas y eventos.</Text>
                </View>
            </TouchableOpacity>
        </ScrollView>
        </View>
        <TouchableOpacity onPress={() => navigation.navigate('SignIn')}><Text style={{color:'#3e5ba6'}}>{"<"}¿Ya tienes cuenta? Inicia sesión</Text></TouchableOpacity>
    </View>
  </>)
}


const styles = StyleSheet.create({
    container: {},
    view: {
    //   marginTop: 100,
    //   backgroundColor: 'blue',
    //   width: width - 200,
    //   margin: 10,
    //   height: 200,
    //   borderRadius: 10,
      //paddingHorizontal : 30
     
        height:500,
        width: 250,
        margin : 15,
        backgroundColor:"white",
        borderRadius:15,
        elevation:10,
        padding:10
 
    },

  });

export default SignUpScreen
