// import {decode as atob, encode as btoa} from 'base-64'

import React, { useState } from 'react'
import {
      StyleSheet,
  Text,
  View,
  TextInput,
  Button,
  TouchableHighlight,
  Image,
  Alert,
  ScrollView,
  Platform,
  Modal,
  Pressable
} from 'react-native'
import { useStoreActions, useStoreState } from 'easy-peasy';
// import { getConfig } from '../../core/config';
import axios from 'axios';
// import AsyncStorage from '@react-native-async-storage/async-storage';
import { API_URL, MEDIA_URL } from '../../core/api';
import * as ImagePicker from 'expo-image-picker';
import * as DocumentPicker from 'expo-document-picker';
// import * as FileSystem from 'expo-file-system';

    
const EditProfileScreen = () => {

    // React Hooks: Al parecer los StoreState siempre se tienen que utilizar en el
    // mismo function en el que haces el componente con return (en class es)
    

    const Config = useStoreState(state => state.AuthConfig)
    const signOut = useStoreActions((action) => action.signOut);

    
    async function handleDelete () {
      await axios.delete(API_URL+'user/'+username+'/delete/', Config)
      .then( signOut())
    }

    async function handleSubmit () {
      console.log(bio, 'status?')
     var newProfile = {
        name: name === undefined ? '' : name,
        current_password: valuepass,
        new_password: undefined ? '' : newPassword,
        bio: bio === undefined ? '' : bio,
        status: status === undefined ? '' : status,
        };
      console.log('newProfile object: ', newProfile)
     if (valuepass == undefined | valuepass == ''){
       setErrortext('Introduce tu contraseña actual para modificar tu perfil')
       setSuccesstext('')
      }else{
      await axios.put(API_URL+'user/'+username+'/edit/', newProfile, Config)
      //.then( setprueba('bieeen')
        //   res => {
        //   console.log(res.data, 'axiosresp')
        //   setSuccesstext('Sus datos han sido cambiados correctamente. ')
        //   setErrortext('')
           
        //    setAuthdata({status, bio, name})
        //    LogginStorage({status, bio, name})

        // }
      //  )
     // .catch( setprueba('maal'))       
      }

      if (imageWeb !== null) {
        await uploadImage_web()
      }

      setValuepass('')// Prevent spamming so user have to keep entering password for every edit submission   
    }

async function uploadavatar (result) {
  let dataform = new FormData();
  dataform.append("file", { uri: result.uri, type: result.mimeType });
  console.log(dataform);

  const res = await axios.post(
    API_URL +'user/uploadavatar/',
    { data: dataform },
    {
      headers: {
        "Authorization": "Token "+token,
        "Content-Type": "multipart/form-data; ",
      },
    }
  );

  return res.data;
}






    const [modalVisible, setModalVisible] = useState(false);
    const [errortext, setErrortext] = useState('');
    const [successtext, setSuccesstext] = useState('');
    const [valuepass, setValuepass] = useState('');
    //const name = useStoreState((state) => state.name);
    const username = useStoreState((state) => state.username);
    const prebio = useStoreState((state) => state.bio);
    const prestatus = useStoreState((state) => state.status);
    const prename = useStoreState((state) => state.name);
    const preavatar = useStoreState((state) => state.avatar);
      const token = useStoreState((state) => state.token);
      const [prueba, setprueba] = useState(token);
    const setAuthdata = useStoreActions(action => action.setAuthdata)
    const LogginStorage = useStoreActions(action => action.LogginStorage)

    const [name, setName] = useState(undefined);
    const [bio, setBio] = useState(undefined);
    const [status, setStatus] = useState(undefined);
    const [newPassword, setNewPassword] = useState(undefined);
    const [image, setImage] = useState(null);
    const [imageWeb, setImageWeb] = useState(null);
    const [launchCameraResult, setLaunchCameraResult] = useState(null)

    const pickImage_native = async () => {
      let permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();

      if (permissionResult.granted === false) {
        alert('Permission to access camera roll is required!');
        return;
      }
      const {base64} = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        base64: true,
      })
      // setprueba('piccssk'+ JSON.stringify(res))
      const base64ToUpload = 'data:image/jpeg;base64,${base64}';

        const url = base64ToUpload;
        fetch(url)
          .then(res => {res.blob(), setprueba('cancl22')})
          .catch( error => setprueba(JSON.stringify(error)))
          // .then(res => {res.arrayBuffer(), setprueba('cancl22')})
          .then(buf => {
        const file = new File([buf] , username+'.png', { type: 'image/png'}) 
        // setprueba('filee'+ JSON.stringify(file))
        setprueba('cancl22')
        const formData = new FormData();
        formData.append('avatar', file);
        axios.post(API_URL +'user/uploadavatar/', formData, Config)
        .then( res => {
                setImage(url)
                setprueba('exito!')
                let avatar = res.data.avatar.split('/media/')[1]
                setAuthdata({avatar})
                LogginStorage({avatar})   
          })
          .catch( error => setprueba(JSON.stringify(error)))
      })
      // }
    }
  

    async function pickImage_web () {
      const res = await DocumentPicker.getDocumentAsync({
         type: 'image/*',
      });
      //Printing the log realted to the file
      // console.log('res : ' + JSON.stringify(res));
      // console.log('URI : ' + res.uri);
      // console.log('Type : ' + res.type);
      // console.log('File Name : ' + res.name);
      // console.log('File Size : ' + res.size);
      //Setting the state to show single file attributes
      setImageWeb(res)
      console.log('queesimageweb', res)
    }
  
    async function uploadImage_web () {

      const url = imageWeb.uri;
      console.log('el url para el fetch_ ', url)
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], imageWeb.name,{ type: imageWeb.mimeType}) 
            console.log('blooob' + blob)
             
      const formData = new FormData();
      formData.append('avatar', file);
    setprueba('llegamos a webb medio!' + JSON.stringify(formData))
      axios.post(API_URL +'user/uploadavatar/', formData, Config)
      .then( res => {
              // setImageWeb(url)
              console.log('success')
              let avatar = res.data.avatar.split('/media/')[1]
              setAuthdata({avatar})
              LogginStorage({avatar})   
        })
      })
    } 

    const ChangingAvatar = () => {
      return(<><Image source={{ uri: imageWeb.uri }} style={{ width: 180, height: 180 }} />
        <TouchableHighlight style={[styles.buttonAvatar, styles.loginButton, {backgroundColor:'red', margin:'auto'}]} onPress={setImageWeb(null)}>
       <Text style={styles.loginText}>Cancelar</Text>
      </TouchableHighlight> {console.log('imageeeweebb', imageWeb)}</>)
    }

    
    return (
        <ScrollView>
        <View style={styles.container}>
        


        {Platform.OS!=='web'?(<>    
        <Image source={{ uri: MEDIA_URL + preavatar }} style={{ width: 200, height: 200, borderRadius:15 }} />
        <Text style={{fontSize: 10}}>Sólo puedes cambiar tu avatar en la web{'\n'}</Text>
        </>):null}  


        {Platform.OS==='web'?(
          <>
            {imageWeb === null ?(<><Image source={{ uri: MEDIA_URL + preavatar }} style={{ width: 180, height: 180, borderRadius:15 }} />
                          <TouchableHighlight style={[styles.buttonAvatar, styles.loginButton, {margin:'auto'}]} onPress={pickImage_web}>
                          <Text style={styles.loginText}>Añadir avatar</Text>
                          </TouchableHighlight>
                          </>):null}
                          {/*  ()} */}
            {imageWeb && <View><Image source={{ uri: imageWeb.uri }} style={{ width: 180, height: 180 }} /><TouchableHighlight style={[styles.buttonAvatar, styles.loginButton, {backgroundColor:'#7F2626', margin:'auto'}]} onPress={() => setImageWeb(null)}>
                          <Text style={styles.loginText}>Cancelar</Text>
                          </TouchableHighlight></View>}

            <Text>{'\n'}</Text>
          </>
        ):null}



        <Text>Nombre</Text>
        <View style={styles.inputContainer}>
          {/* <Image style={styles.inputIcon} source={{uri: 'https://png.icons8.com/message/ultraviolet/50/3498db'}}/> */}
          <TextInput style={styles.inputs}
              placeholder={prename}
              name = "name"
              //keyboardType="email-address"
              underlineColorAndroid = 'transparent'
              onChangeText={(value) => setName(value)}/>
        </View>
        <Text>Biografía: Descríbete brevemente</Text>
        <View style={styles.inputContainer}>
            <TextInput style={styles.inputs}
              name = "bio"
              numberOfLines={2}
              placeholder={prebio}
              underlineColorAndroid='transparent'
              onChangeText={(value) => setBio(value)}/>
        </View>
        <Text>Contraseña actual <Text style={{color:'#3e5ba6'}}>(Obligatoria)</Text></Text>
        <View style={styles.inputContainer}>
          <TextInput style={styles.inputs}
              secureTextEntry={true}
              name = "currentPassword"
              onSub
              value={valuepass}
              underlineColorAndroid='transparent'
              onChangeText={(value) => setValuepass(value)}/>
        </View>
        <Text>Nueva contraseña</Text>
        <View style={styles.inputContainer}>
          <TextInput style={styles.inputs}
              secureTextEntry={true}
              name = "newPassword"
              placeholder=""
              underlineColorAndroid='transparent'
              onChangeText={(value) => setNewPassword(value)}/>
        </View>
        <Text>Ocupación: ¿A qué te dedicas?</Text>
        <View style={styles.inputContainer}>
          <TextInput style={styles.inputs}
              name = "status"
              placeholder={prestatus}
              underlineColorAndroid='transparent'
              onChangeText={(value) => setStatus(value)}/>
        </View>
        <Text style={{color: 'red'}}>{errortext}</Text>
        <Text style={{color: 'green'}}>{successtext}</Text>
        <TouchableHighlight style={[styles.buttonContainer, styles.loginButton]} onPress={handleSubmit}>
          <Text style={styles.loginText}>Guardar cambios</Text>
        </TouchableHighlight>
        <TouchableHighlight style={[styles.deletebuttonContainer, styles.deleteButton]} onPress={() => setModalVisible(true)}>
          <Text style={styles.deleteText}><Text style={{color:"white", backgroundColor:"#EC5800", fontWeight:'bold', borderRadius:30, paddingHorizontal:5}}>!</Text> Eliminar cuenta</Text>
        </TouchableHighlight>


        <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert('Modal has been closed.');
          setModalVisible(!modalVisible);
        }}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={[styles.modalText, {fontWeight: 'bold', fontSize: 18}]}>¡Borrado de cuenta!</Text>
            <Text style={styles.modalText}>Se eliminará tu cuenta y borrarán tus datos de forma permanente. Este proceso no tiene vuelta atrás. ¿Estás seguro de querer continuar? </Text>
            <View style={{flexDirection:'row'}}>
              <Pressable
                style={[styles.button, styles.buttonClose]}
                onPress={() => setModalVisible(!modalVisible)}>
                <Text style={styles.textStyle}>Cancelar</Text>
              </Pressable>
              <Pressable
                style={[styles.button, styles.buttonClose, {backgroundColor: '#C41E3A'}]}
                onPress={handleDelete}>
                <Text style={styles.textStyle}>Eliminar cuenta</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>


      </View>
      </ScrollView>
    )
}

export default EditProfileScreen

const styles = StyleSheet.create({
    container: {
      marginTop: 30,
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      /* backgroundColor: '#DCDCDC', */
    },
    inputContainer: {
        borderBottomColor: '#F5FCFF',
        backgroundColor: '#FFFFFF',
        borderRadius:30,
        borderBottomWidth: 1,
        width:250,
        height:45,
        marginBottom:20,
        flexDirection: 'row',
        alignItems:'center'
    },
    inputs:{
        height:45,
        marginLeft:16,
        borderBottomColor: '#FFFFFF',
        flex:1,
    },
    inputIcon:{
      width:30,
      height:30,
      marginLeft:15,
      justifyContent: 'center'
    },
    buttonContainer: {
      height:45,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom:20,
      width:250,
      borderRadius:30,
    },
    deletebuttonContainer: {
      height:35,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom:20,
      width:150,
      borderRadius:10,
    },
    buttonAvatar: {
      height:30,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom:20,
      width:120,
      borderRadius:30,
    },
    loginButton: {
      backgroundColor: '#3e5ba6',
    },
    deleteButton: {
      backgroundColor: '#C41E3A',
    },
    loginText: {
      color: 'white',
    },
    deleteText: {
      color: 'white',
    },

    modalView: {
      margin: 20,
      backgroundColor: 'white',
      borderRadius: 20,
      padding: 35,
      alignItems: 'center',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
    },
    button: {
      borderRadius: 20,
      padding: 10,
      elevation: 2,
    },
    buttonClose: {
      backgroundColor: '#2196F3',
      marginHorizontal: 5
    },
    textStyle: {
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    modalText: {
      marginBottom: 15,
      textAlign: 'center',
    },
  });
