// import { View, Text, useWindowDimensions, TouchableOpacity } from "react-native";
// // import LandingPage from "./LandingPage";
// import { Standing11, Standing1, Sitting4 } from 'react-humaaans';
// import { useNavigation } from "@react-navigation/native";
// import { ScrollContainer, ScrollPage } from "react-scroll-motion";
// import { Icon } from "react-native-elements";

// export default function Features () {
// const {width, height} = useWindowDimensions()
// const navigation = useNavigation()

// return(
//   <View style={{paddingVertical:50}}>
// <ScrollContainer>
//   <ScrollPage page={0}>
//     <View style={{flexDirection:'row'}}>
//       <View style={{padding: width>500?50:5, justifyContent:'center', alignItems:'center', width: width/2, height:height, borderColor:'#3e5ba6', borderWidth:3, borderBottomColor:'transparent', borderLeftColor:'transparent', borderTopEndRadius:10}}>
//         <Text style={{ padding:5, fontSize: 20, fontWeight:'bold', color:'#3e5ba6'}}>Una plataforma virtual para la terapia</Text>
//         <Text style={{ padding:5, color: '#444444', fontSize: 16, fontWeight:'bold'}}> {'\n'}{'\n'}Un medio digital donde hablar en tiempo real con tu terapeuta, fijar sesiones de terapia o visualizar tu progreso terapéutico. ¡Y mucho más!{"\n"}</Text>
//       </View>          
//       <View style={{width: width/2, height:height, justifyContent:'center', alignItems:'center'}}>
//         <Standing1
//             height={width/2 > height? height:width/2}
//             hairColor="#000000"
//             skinColor="#FDA7DC"
//             shoeColor="#413F42"
//             // pantColor="red"
//             // shirtColor="#9A86A4"
//             coatColor="#9A86A4"
//             hatColor="green"
//           />
//       </View>
//     </View>
      
//   </ScrollPage>
//   <ScrollPage page={1}>
//   <View style={{flexDirection:'row'}}>
//   <View style={{width: width/2, height:height, justifyContent:'center', alignItems:'center'}}>
//         <Sitting4
//             height={width/2 > height? height:width/2}
//             hairColor="#000000"
//             skinColor="#FDA7DC"
//             shoeColor="#413F42"
//             // pantColor="red"
//             // shirtColor="#9A86A4"
//             coatColor="#9A86A4"
//             hatColor="green"
//           />
//       </View>
//           <View   style={{padding: width>500?50:5, justifyContent:'center', alignItems:'center', width: width/2, height:height, borderColor:'#5e5ba6', borderWidth:3, borderTopColor:'transparent', borderBottomColor:'transparent', borderRightColor:'transparent'}}>
//               <Text style={{ padding:5, color: '#333333', fontSize: 20, fontWeight:'bold', color:'#3e5ba6'}}>Comprendiendo la mente, mejorando la terapia</Text>
//               <Text style={{ padding:5, color: '#444444', fontSize: 16, fontWeight:'bold'}}> {'\n'}{'\n'}Usamos los datos anonimizados de la plataforma con herramientas científicas para avanzar en la ciencia y mejorar las herramientas de la plataforma.{"\n"}</Text>
     
//           </View>
//   </View>
//   </ScrollPage>
//   <ScrollPage page={0}>
//     <View style={{flexDirection:'row'}}>
//       <View style={{padding: width>500?50:5, justifyContent:'center', alignItems:'center', width: width/2, height:height, borderColor:'#3e5ba6', borderWidth:3, borderTopColor:'transparent', borderLeftColor:'transparent', borderTopEndRadius:10}}>
//         <Text style={{ padding:5, color: '#333333', fontSize: 20, fontWeight:'bold', color:'#3e5ba6'}}>Entra en nuestra aplicación y comienza tu terapia</Text>
//         <Text style={{ padding:5, color: '#444444', fontSize: 16, fontWeight:'bold'}}> {'\n'}{'\n'}¡¿Todavía no usas PsykeHub?! Si eres terapeuta, haz una cuenta de terapeuta y empieza a agendar tus pacientes. {'\n'}{'\n'}Si no.. ¡Dile a tu terapeuta que empieze a usar PsykeHub ya!{"\n"}</Text>
     
//         <TouchableOpacity style={{ flexDirection:'row', backgroundColor: '#222222', width: 150, alignItems:'center'/* paddingHorizontal: 40 */, borderRadius: 10, padding: 10, margin: 10 }}
//             onPress={() => { navigation.navigate("Authentication")}}>
//             <Icon color="#FFFFFF" name="browser" type="octicon"  style={{width:30, height:30, marginHorizontal:10}} />
//             <Text style={{ color: '#FFF', fontSize: 20 }}>
//                 Entrar
//             </Text>
//         </TouchableOpacity>
//       </View>
//       <View style={{width: width/2, height:height, justifyContent:'center', alignItems:'center'}}>
//         <Standing11
//             height={width/2 > height? height:width/2}
//             hairColor="#000000"
//             skinColor="#FDA7DC"
//             shoeColor="#413F42"
//             // pantColor="red"
//             // shirtColor="#9A86A4"
//             coatColor="#9A86A4"
//             hatColor="green"
//           />
//       </View>
//     </View>
      
//   </ScrollPage>
// </ScrollContainer>
// </View>)
// }


import { View, Text, useWindowDimensions, TouchableOpacity } from "react-native";
// import LandingPage from "./LandingPage";
// import { Standing11, Standing1, Sitting4 } from 'react-humaaans';
import { useNavigation } from "@react-navigation/native";
import { ScrollContainer, ScrollPage } from "react-scroll-motion";
import { Icon, Image } from "react-native-elements";
import Dispositivos from "../../../assets/featuresPics/Dispositivos.svg"
import EstadisticasUser from "../../../assets/featuresPics/EstadisticasUser.svg"
import TherapistUserComunnication from "../../../assets/featuresPics/TherapistUserComunnication.svg"

export default function Features () {
const {width, height} = useWindowDimensions()
const navigation = useNavigation()

return(
  <View style={{paddingVertical:50}}>

    <View style={{flexDirection:'row'}}>
      <View style={{padding: width>500?50:5, justifyContent:'center', alignItems:'center', width: width/2, height:height, borderColor:'#3e5ba6', borderWidth:3, borderBottomColor:'transparent', borderLeftColor:'transparent', borderTopEndRadius:10}}>
        <Text style={{ padding:5, fontSize:  width>700?35:20, fontWeight:'bold', color:'#3e5ba6'}}>Una plataforma virtual para la terapia</Text>
        <Text style={{ padding:5, color: '#444444', fontSize: width>700?25:16, fontWeight:'bold'}}> {'\n'}{'\n'}Un medio digital donde hablar en tiempo real con tu terapeuta, fijar sesiones de terapia o visualizar tu progreso terapéutico. ¡Y mucho más!{"\n"}</Text>
      </View>          
      <View style={{width: width/2, height:height, justifyContent:'center', alignItems:'center'}}>
        {/* <Standing1
            height={width/2 > height? height:width/2}
            hairColor="#000000"
            skinColor="#FDA7DC"
            shoeColor="#413F42"
            // pantColor="red"
            // shirtColor="#9A86A4"
            coatColor="#9A86A4"
            hatColor="green"
          /> */}
        <Image source={Dispositivos} style={{width : width / 2 -20, height: width/4 -40}} />
      </View>
    </View>
      

  <View style={{flexDirection:'row'}}>
  <View style={{width: width/2, height:height, justifyContent:'center', alignItems:'center'}}>
        {/* <Sitting4
            height={width/2 > height? height:width/2}
            hairColor="#000000"
            skinColor="#FDA7DC"
            shoeColor="#413F42"
            // pantColor="red"
            // shirtColor="#9A86A4"
            coatColor="#9A86A4"
            hatColor="green"
          /> */}
           <Image source={EstadisticasUser} style={{width : width / 2 -20, height: width/4 -40}} />
      </View>
          <View   style={{padding: width>500?50:5, justifyContent:'center', alignItems:'center', width: width/2, height:height, borderColor:'#5e5ba6', borderWidth:3, borderTopColor:'transparent', borderBottomColor:'transparent', borderRightColor:'transparent'}}>
              <Text style={{ padding:5, color: '#333333', fontSize:  width>700?35:20, fontWeight:'bold', color:'#3e5ba6'}}>Comprendiendo la mente, mejorando la terapia</Text>
              <Text style={{ padding:5, color: '#444444', fontSize: width>700?25:16, fontWeight:'bold'}}> {'\n'}{'\n'}Usamos los datos anonimizados de la plataforma con herramientas científicas para avanzar en la ciencia y mejorar las herramientas de la plataforma.{"\n"}</Text>
     
          </View>
  </View>

    <View style={{flexDirection:'row'}}>
      <View style={{padding: width>500?50:5, justifyContent:'center', alignItems:'center', width: width/2, height:height, borderColor:'#3e5ba6', borderWidth:3, borderTopColor:'transparent', borderLeftColor:'transparent', borderTopEndRadius:10}}>
        <Text style={{ padding:5, color: '#333333', fontSize:  width>700?35:20, fontWeight:'bold', color:'#3e5ba6'}}>Entra en nuestra aplicación y comienza tu terapia</Text>
        <Text style={{ padding:5, color: '#444444', fontSize: width>700?25:16, fontWeight:'bold'}}> {'\n'}{'\n'}¡¿Todavía no usas PsykeHub?! Si eres terapeuta, haz una cuenta de terapeuta y empieza a agendar tus pacientes. {'\n'}{'\n'}Si no.. ¡Dile a tu terapeuta que empieze a usar PsykeHub ya!{"\n"}</Text>
     
        <TouchableOpacity style={{ flexDirection:'row', backgroundColor: '#222222', width: 150, alignItems:'center'/* paddingHorizontal: 40 */, borderRadius: 10, padding: 10, margin: 10 }}
            onPress={() => { navigation.navigate("Authentication")}}>
            <Icon color="#FFFFFF" name="browser" type="octicon"  style={{width:30, height:30, marginHorizontal:10}} />
            <Text style={{ color: '#FFF', fontSize: 20 }}>
                Entrar
            </Text>
        </TouchableOpacity>
      </View>
      <View style={{width: width/2, height:height, justifyContent:'center', alignItems:'center'}}>
        {/* <Standing11
            height={width/2 > height? height:width/2}
            hairColor="#000000"
            skinColor="#FDA7DC"
            shoeColor="#413F42"
            // pantColor="red"
            // shirtColor="#9A86A4"
            coatColor="#9A86A4"
            hatColor="green"
          /> */}
           <Image source={TherapistUserComunnication} style={{width : width / 2 -20, height: width/4 -40}} />
      </View>
    </View>
      

</View>)
}
