import React from "react";
import { View, Text, Linking, Image, useWindowDimensions } from "react-native";
import {BlurView} from "expo-blur";
import logo from '../../../assets/LogoPSYKEHUBlowr.png'

const NotFoundPage = () => {
    const {width, height} = useWindowDimensions()
    return(<>
    <Image source={logo} style={{width:750, height:450, position:'absolute', left:(width/2)-300, top:(height/2)-225}} />

        <BlurView intensity={90} tint={"light"}
         style={{padding:100, borderRadius:20, margin:'auto', alignItems:'center', justifyContent:'center', width:"100%"}} >
           
           
           <Text style={{fontSize:20}}>Parece que.. <Text style={{fontWeight:'bold'}}>¡no encontramos la página que estabas buscando!</Text>{"\n"}{"\n"}</Text>
           <Text onPress={() => Linking.openURL('/')} style={{color:'#3e5ba6'}}>Volver al inicio {'->'}</Text>

        </BlurView>
    </>)
}
export default NotFoundPage;