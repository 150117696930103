import { useStoreState } from 'easy-peasy'
import React, { useEffect } from 'react'
import { View, Text, TouchableOpacity, StyleSheet, useWindowDimensions } from 'react-native'
import { Chat } from '../../components/Chat'
import { Dimensions } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Avatar } from 'react-native-elements';

const {width} = Dimensions.get('window')

const ChatsTherapistScreen = () => {
    const {width} = useWindowDimensions();
    const dataPatients = useStoreState( state => state.dataPatients)
    const navigation = useNavigation()
    var notifications = useStoreState(state => state.notifications)
    const notificationsUsers= Object.keys(notifications)
    //var recipient = null
    const TopChats = dataPatients.map((d,i) =>
  ( notificationsUsers.includes(d.username)?(<View>{d.therapylink.accepted ? ( // Si no está accepted link, no se muestra
      <TouchableOpacity onPress={() => navigation.navigate('ChatTherapistScreen', {d})}>
    <View style={[styles.view,{ paddingLeft: 15, flexDirection:'row', alignItems:'center'}]} >
          <Avatar source={ { uri: d.avatar}} rounded={true} size={50} />
       <Text style={{padding:5, fontWeight:'bold'}}>    {d.name} <Text style={{color:'#3e5ba6'}}>({d.username})    </Text></Text>
       <Text style={{backgroundColor:'#3e5ba6', color:'white', padding:5, alignContent:'flex-end', borderRadius:15}}> {notifications[d.username]} </Text>
       </View>{/* #cb3b38 #7d4052 rgb(195, 64, 82)*/}
       </TouchableOpacity>
    ): null}</View>):null)
    )
    const RestOfChats = dataPatients.map((d,i) =>
  ( !notificationsUsers.includes(d.username)?(<View>{d.therapylink.accepted ? ( // Si no está accepted link, no se muestra
      <TouchableOpacity onPress={() => navigation.navigate('ChatTherapistScreen', {d})}>
    <View style={[styles.view,{ paddingLeft: 15, flexDirection:'row', alignItems:'center'}]} >
          <Avatar source={ { uri: d.avatar}} rounded={true} size={50} />
       <Text style={{padding:5, fontWeight:'bold'}}>    {d.name} <Text style={{color:'#3e5ba6'}}>({d.username})</Text></Text>
       </View>
       </TouchableOpacity>
    ): null}</View>):null)
    )

console.log( 'notificationsss: ', TopChats)
    return (
<View style={{width:width>768?"80%":"100%", marginHorizontal:'auto'}}>
                {/* <Chat userpk ={userpk} recipient = {therapistPk}/> */}
                {/* <Text>No hay mensajes / Estos son los mensajes y de aquién </Text> */}

{/* <Text>usuarios aquí en lista descendente </Text> */}
{TopChats}
{RestOfChats}
</View>

    )
}

const styles = StyleSheet.create({

    view: {
        height:50,
        width: width ,
        margin : 5,
        backgroundColor:"white",
        borderRadius:15,
        elevation:10,
        padding:10
  
    },
  
  });

export default ChatsTherapistScreen


/// Vamos a cmabiar Example por chat y eliminar el old. Luego hacer un marco arrribva con ela vatar y el nombre en grande y pasar por props el link y nombre para abajo.