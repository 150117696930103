import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";
import ChatPatientScreen from '../../patient/ChatPatientScreen';
import AvatarNavHeader from '../../../components/AvatarNavHeader';

const ChatPatientStack = createStackNavigator();
const ChatPatientStackScreen = ({ navigation }) =>{
  
  return(
    <ChatPatientStack.Navigator>
      <ChatPatientStack.Screen
        name={"ChatPatientScreen"}
        component={ChatPatientScreen}
        options={{
          title: "Chat",
          headerLeft : () => {
            return <AvatarNavHeader />
          },
        }}
      />
      {/* <ChatPatientStack.Screen
        name={"Detalles de Chat Screen"}
        component={Detalles de Chat Screen}
        options={{
          title: "Editar perfil",
        }}
    /> */}
    </ChatPatientStack.Navigator>
  )
}
export default ChatPatientStackScreen