import { useNavigation } from "@react-navigation/native";
import { useStoreState } from "easy-peasy";
import React from "react";
import { TouchableOpacity, Text, Platform } from "react-native";
import { Icon } from "react-native-elements";

const NotificationChatTherapist = () =>{
    const navigation = useNavigation()
    var notifications = useStoreState(state => state.notifications)
    
    //console.log('NOTIFICHAT', notifications)//Object.keys(notifications).length > 0)
    
    var count = 0
    if (notifications !== undefined){ 
        if(Object.keys(notifications).length > 0){
        
            Object.keys(notifications).map((k,i) => {
            count = count + notifications[k]
            })

            return(<>
                <TouchableOpacity onPress={() => navigation.navigate('ChatTherapistStackScreen')} style={{backgroundColor:'#3e5ba6', padding:Platform.OS == 'web'?7:3, borderRadius: 10, marginHorizontal:10, flexDirection:'row', alignItems:'center'}}>
                    <Text style={{fontSize:20, fontWeight:'bold', color:'white'}}>{count}</Text>
                    <Icon  name="notifications" size={20} type="ionicons" color="white" /> 
                </TouchableOpacity>
            </>)
        }else{
            return null
        }
    }else{
        return null
    }
}
export default NotificationChatTherapist