import isEmpty from 'lodash/isEmpty';
import React, {Component, useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import { API_URL } from '../core/api';

import { Calendar } from "react-native-event-week";
import 'dayjs/locale/es'
import { useStoreState } from 'easy-peasy';
import moment from 'moment';
import { useNavigation } from '@react-navigation/native';

const AgendaTherapist = () => {

  const userpk = useStoreState(state => state.userpk)
  const Config = useStoreState(state => state.AuthConfig)
  const [events, setEvents] = useState([])
  const navigation = useNavigation()

var buildata = []; 
  function GetAppointments (){
    // const Config = await getConfig()
   console.log("cogiendo datos")
    axios.get(API_URL+'appointments/'+userpk+'/listbytherapist/', Config)
      .then(res => {
        
        var options = { weekday: 'long', year: 'long', month: 'long', day: 'long' };
        for (var i = 0, max = res.data.length; i < max; i += 1) {
          if (res.data[i].appointment_at != null) {
            buildata.push({
                title: res.data[i].user_name,
                start: moment(res.data[i].appointment_at).local().format('YYYY-MM-DD HH:mm'),
                end: moment(res.data[i].appointment_at).add(1,'hours').local().format('YYYY-MM-DD HH:mm'),
                backgroundColor: "#3e5ba6",

                duration: '1h',
                title: "Cita con su terapeuta",
                hour: new Date(res.data[i].appointment_at).toLocaleTimeString(),
                appointment_at: res.data[i].appointment_at,
                created_at: res.data[i].created_at,
                pk: res.data[0].pk,
                therapist_id: res.data[0].therapist_id,
                therapist_name: res.data[0].therapist_name,
                user_id: res.data[0].user_id,
                user_name: res.data[0].user_name,
            } )
          }console.log(res.data[i])
        }setEvents(buildata)
      })
          .catch( error => {
        console.log(error)
    });
        
  }  
  
  


  // const events = [
  //   {
  //     title: "Eduardo",
  //     start: "2022-04-06 14:45",
  //     end: "2022-04-06 15:15",
  //     backgroundColor: "#3e5ba6",
  //   },
  //   {
  //     title: "Coffee break",
  //     start: "2022-04-08 06:45",
  //     end: "2022-04-08 07:15",
  //     backgroundColor: "#3e5ba6",
  //   },
  // ];

  useEffect(() => GetAppointments(), [])

  return(
    <Calendar events={events} height={400} 
    weekStartsOn={1} locale="es" scrollOffsetMinutes={600} 
    style={{backgroundColor:'white'}}
    refreshing = "false"  /// en futuro sí!
    onPressEvent = {(item) => navigation.navigate('EventPatientScreen', {item})}
    onRefresh={() => null} 
    />
  )
}














export default AgendaTherapist