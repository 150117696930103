import React from "react";
import { View, Image, Text, Linking } from "react-native";
import logo from "../../../assets/LogoPSYKEHUBlowr.png"
import { BlurView } from "expo-blur";
import { TouchableOpacity } from "react-native-gesture-handler";

function Header (){

    return(<>        
    <BlurView intensity={90} tint={"light"} style={{width:"100%", height:70, marginBottom:20, flexDirection:'row'}}>
        <TouchableOpacity onPress={() => window.location = '/'} style={{flexDirection:'row', marginVertical:'auto'}}>
            <Image source={logo} style={{height:50, width:80, marginHorizontal:5, marginVertical:'auto'}} />
            <Text style={{color:'#3e5ba6', marginVertical:'auto', fontSize:16}} > PSYKE<Text style={{fontWeight:'bold'}} >HUB</Text></Text>
        </TouchableOpacity>
   </BlurView>
    </>)
}
export default Header;
