
import React from 'react'

import EventRecord from '../../components/EventRecord';

const RecordTherapistScreen = ({route}) => {
    return (<>
        <EventRecord route={route} />
    </>)
}

export default RecordTherapistScreen
