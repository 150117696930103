import { useStoreState } from 'easy-peasy';
import React from 'react';
import { View } from 'react-native';
// import { Avatar } from 'react-native-paper';
import { MEDIA_URL } from '../core/api';
//import { Avatar } from 'react-native-paper';
// import { Avatar } from 'native-base';
import { Avatar, Text } from 'react-native-elements';
import { LinearGradient } from 'expo-linear-gradient';

// const FROM_COLOR = 'rgb(255, 255, 255)';
// const TO_COLOR = 'rgb(0,102,84)';


const GradientText = () => {
    const avatar = useStoreState((state) => state.avatar);
    const name = useStoreState((state) => state.name);
    const username = useStoreState((state) => state.username);
    // const colorList = [
    //   {offset: '0%', color: '#231557', opacity: '1'},
    //   {offset: '29%', color: '#44107A', opacity: '1'},
    //   {offset: '67%', color: '#FF1361', opacity: '1'},
    //   {offset: '100%', color: '#FFF800', opacity: '1'}
    // ]

  return (   <LinearGradient
    // Linear Gradient  - In PRO put picture
    //colors={['#3e5ba6', '#444cb3', '#843361'] }
    //////colors={['#6ba4e1', '#4f6fbd', '#b362d5'] } //8c6adf
            /////colors={['#2363a3', '#343556', '#6b3b4e']}//
            colors={['#2363a3', '#3e5ba6', '#6e3c64']}//
    //color = {['#8482e0', '#ab93d8', '#a433d2']}
    style={{width:"100%"}}
    >
  <View style={{flex:1, alignItems:'center', margin:30}}>
    <Avatar
      size={64}
      rounded
      source={
        {uri:
          MEDIA_URL + avatar,
          // 'https://cdn.pixabay.com/photo/2018/04/18/18/56/user-3331256__340.png',
      }}
      
    />
    <Text h4 style={{marginTop:10, color:'white'}}>{name}</Text>
    <Text style={{marginBottom:10, color:'white'}}>{username}</Text>
  </View> 
  </LinearGradient>
  );
};

export default GradientText;