import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";
import PatientsTherapistScreen from '../../therapist/PatientsTherapistScreen';
import AppointmentTherapistScreen from '../../therapist/AppointmentTherapistScreen';
import NotificationChatTherapist from '../../../components/NotificationChatTherapist';
import AvatarNavHeader from '../../../components/AvatarNavHeader';

const PatientsTherapistStack = createStackNavigator();
const PatientsTherapistStackScreen = ({ navigation }) =>{
  return(
    <PatientsTherapistStack.Navigator>
      <PatientsTherapistStack.Screen
        name={"PatientsTherapistScreen"}
        component={PatientsTherapistScreen}
        options={{
          title: "Pacientes",
          headerLeft : () => {
            return <AvatarNavHeader />
          },
          headerRight : () => {
            return (
              <NotificationChatTherapist />
            )
          },
        }}
      />
      {/* <EveeentsTherapistStack.Screen
        name={"EventTherapistScreen"}
        component={EventTherapistScreen}
        options={{
          title: "Editar perfil",
        }}
    /> */}
      {/* <PatientsTherapistStack.Screen
        name={"Detalles de Patients Screen"}
        component={Detalles de Patients Screen}
        options={{
          title: "Editar perfil",
        }}
    /> */}
    </PatientsTherapistStack.Navigator>
  )
}
export default PatientsTherapistStackScreen