import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import AppPH from './AppPH_web';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Emailverify from './src/web/pages/Emailverify';
import NotFoundPage from './src/web/pages/NotFoundPage';
import Requestresetpass from './src/web/pages/Requestresetpass';
import Passwordreset from './src/web/pages/Passwordreset';
import PrivacyPolicy from './src/web/pages/PrivacyPolicy';
import LegalNotice from './src/web/pages/LegalNotice';
import CookiesPolicy from './src/web/pages/CookiesPolicy';
import Cookies from './src/web/components/Cookies';
import Features from './src/web/pages/Features';
// import LandingPage from './web/pages/LandingPage';

import ReactGA from 'react-ga';
import { useEffect } from 'react';
const TRACKING_ID = "UA-231712108-1"; // OUR_TRACKING_ID Universal !!(It is not GA4. In july 2023 only will work Ga4)
ReactGA.initialize(TRACKING_ID);

export default function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <View style={styles.container}>
      <Cookies />
      <BrowserRouter>
        <Routes>
        
          {/* <Route path="/user/:username" component={UserProfileContainer} /> */}
              {/* <Route path="/" component ={dashpatient} /> */}
          <Route path="/emailverify/:user_id/token/:confirmation_token" element={<Emailverify />} />
          <Route path="/resetpass" element={<Requestresetpass />} />
          <Route path="/passwordreset/:user/auth/:token" element={<Passwordreset />} />
          <Route path="/politicadeprivacidad" element={<PrivacyPolicy />} />
          <Route path="/avisolegal" element={<LegalNotice />} />
          <Route path="/politicadecookies" element={<CookiesPolicy />} />
          
          
          <Route exact path="/" element={<AppPH />} />
          {/* <Route path="/descubrir" element={<Features />} /> */}
          <Route path="*" element={<NotFoundPage />} /> 

          {/* <Route exact path="/app" element={} /> */}
      {/* <StatusBar style="auto" /> */}
        </Routes>
      </BrowserRouter> 
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#3e5ba6',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
});
