
import React from 'react'

import EventAppointment from '../../components/EventAppointment';

const AppointmentTherapistScreen = ({route}) => {
    return (<>
        <EventAppointment route={route} />
    </>)
}

export default AppointmentTherapistScreen
