import React, { useState } from "react";
import { View, Text, Linking, Image, useWindowDimensions, TextInput } from "react-native";
import {BlurView} from "expo-blur";
import logo from '../../../assets/LogoPSYKEHUBlowr.png'
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../core/api";

function Requestresetpass () {
    const {width, height} = useWindowDimensions()
    const [isSended, setIsSended] = useState(false);
    const [email, setEmail] = useState(null)
    const [errorText, setErrorText] = useState('');
    const [responseAPI, setResponseAPI] = useState(null)

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };

    function handleSubmit() {
        if ( email === undefined || email == null || email.length == 0 ) {
            setErrorText('Debes rellenar el formulario.')
        }else{
        if(!validateEmail(email)){
            setErrorText('Debes introducir una dirección de correo válida.')
        }else{
        axios.post(API_URL+'user/request-reset-email/', {email})
        .then(result => {
            setIsSended(true),
            setResponseAPI(result.data.success),
            setEmail(null)
        }) 
        .catch(error => {
            setResponseAPI(error.response)
        });
      }
      }
    } 
    
    if(isSended){
        return(
        <>
            <Image source={logo} style={{width:750, height:450, position:'absolute', left:(width/2)-300, top:(height/2)-225}} />
            <BlurView intensity={90} tint={"light"}
             style={{padding:100, borderRadius:20, margin:'auto', alignItems:'center', justifyContent:'center', width:"100%"}} >
               <Text style={{fontSize:20}}>{responseAPI}{"\n"}{"\n"}<Text style={{fontWeight:'bold'}}>{email}</Text>{"\n"}{"\n"}</Text>
               <Text onPress={() => Linking.openURL('/')} style={{color:'#3e5ba6'}}>Volver al inicio {'->'}</Text>
            </BlurView>
        </>
         )
      }else{
        return (
        <>
        <Image source={logo} style={{width:750, height:450, position:'absolute', left:(width/2)-300, top:(height/2)-225}} />
        <BlurView intensity={90} tint={"light"}
         style={{padding:100, borderRadius:20, margin:'auto', alignItems:'center', justifyContent:'center', width:"100%"}} >
           <Text style={{fontSize:20}}>Introduce la dirección de correo electrónica con el que te diste de alta tu cuenta{"\n"}{"\n"}</Text>
           <TextInput
            onChangeText={txt => setEmail(txt)}
            placeholder="correo@electronico.com"
            style={{ marginTop: 20, width:250 }} //give custom styles
           />
           <Text>{"\n"}{"\n"}</Text>
           <Text style={{color: 'red'}}>{errorText}</Text>
           <Text onPress={() => handleSubmit()} style={{color:'#3e5ba6'}}>solicitar cambio de contraseña {'->'}</Text>
        </BlurView>
    </>
        );
      }
}
export default Requestresetpass;