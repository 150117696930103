import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";
import CalendarTherapistScreen from '../../therapist/CalendarTherapistScreen';
import AppointmentPatientScreen from '../../patient/AppointmentPatientScreen';
import NotificationChatTherapist from '../../../components/NotificationChatTherapist';
import AvatarNavHeader from '../../../components/AvatarNavHeader';

const CalendarTherapistStack = createStackNavigator();
const CalendarTherapistStackScreen = ({ navigation }) =>{
  return(
    <CalendarTherapistStack.Navigator>
      <CalendarTherapistStack.Screen
        name={"CalendarTherapistScreen"}
        component={CalendarTherapistScreen}
        options={{
          title: "Agenda",
          headerLeft : () => {
            return <AvatarNavHeader />
          },
          headerRight : () => {
            return (
              <NotificationChatTherapist />
            )
          },
        }}
      />
      <CalendarTherapistStack.Screen
        name={"EventPatientScreen"}
        component={EventPatientScreen}
        options={{
          title: "Evento de calendario",
        }}
    />

{/*  No add eventrecord cause not display in schedule  (actually, this is schedule not calendar) */}
    
      {/* <CalendarTherapistStack.Screen
        name={"Detalles de Calendar Screen"}
        component={Detalles de Calendar Screen}
        options={{
          title: "Editar perfil",
        }}
    /> */}
    </CalendarTherapistStack.Navigator>
  )
}
export default CalendarTherapistStackScreen