import { action, thunk, createStore } from 'easy-peasy';
import AsyncStorage from '@react-native-async-storage/async-storage';

// export default 

const STORE = {
    /** STATE */
    isLoggedin: false,

    /** ACTIONS */

    reset: action((state, payload) => {  
        //state.token = undefined
        Object.keys(state).forEach(v => state[v] = undefined)
    }),

    loggin: action((state, payload) => {
        state.isLoggedin = payload;
        console.log('Changing isLoggedin: ', payload)
        //state.isLoggedin = true;
        // console.log('model/store/loggin!')

        ////
        ///////         Tenemos el problema en que cuando logea bien, guarda todo en virtual y storege,
        ///////////           Pero luego si salgo y vuelvo a loguear por segunda vez, no me deja... Draft
        ///
        /// Solucion: Resulta que, si accedía al state desde signOut (como action), se estropeaba.
        ///  parecer como si sólo se puede cambiar state desde 1 mismo action. raro.
        ///   o bien quizás 
   
    }),

    signOut: thunk(async (action, payload) => {
        //state.isLoggedin = false;
        action.reset()
        action.loggin(false);
        //try {
            //await AsyncStorage.removeItem("IS_LOGGED_IN")
            await AsyncStorage.removeItem("token")
            await AsyncStorage.removeItem("userpk")
            await AsyncStorage.removeItem("username")
            await AsyncStorage.removeItem("name")
            await AsyncStorage.removeItem("avatar")
            await AsyncStorage.removeItem("is_user")
            await AsyncStorage.removeItem("is_therapist")
            //await AsyncStorage.removeItem("is_staff")
            //await AsyncStorage.removeItem('AUTH_TOKEN');
            //actions.signOut()
        //} catch (error) {
            console.log('Error en SignOut', error)
        //}

       


        // delete state.isLoggedin;
        // delete state.avatar;
        // delete state.userpk;
        // delete state.username;
        // delete state.name;
        // delete state.avatar;
        // delete state.is_user;
        // delete state.is_therapist;
        // delete state.status;
        // delete state.bio;

    }),

    LogginAccount: thunk(async (actions, payload) => {

        try{

            
            actions.setAuthdata(payload)
            actions.LogginStorage(payload)
             actions.loggin(true);  /// IMPORTANTE: esta línea la última, 
             /// pues tiene que cargar primero los datos en store y persistnt

        }catch(error){
            console.log(error, 'error at logginaccountstograge')
        }
    }),

    LogginStorage: thunk( async (action, payload) => {
        console.log('PAYLOAD at LogginStorage', payload)
        console.log('Payload is?', payload.token === undefined)
        try{  /// De esta forma, sólo estoragea lo que sí le enviamos.
            payload.token === undefined ? null : await AsyncStorage.setItem("token", payload.token);
            // En ocasiones la API lo llamó pk o userpk, metemos uno u otro
            payload.pk === undefined ? null : await AsyncStorage.setItem("userpk", payload.pk.toString());
            payload.userpk === undefined ? null : await AsyncStorage.setItem("userpk", payload.userpk.toString());

            payload.name === undefined ? null : await AsyncStorage.setItem("name", payload.name);
            payload.username === undefined ? null : await AsyncStorage.setItem("username", payload.username);
            payload.avatar === undefined ? null : await AsyncStorage.setItem("avatar", payload.avatar);
            payload.is_user === undefined ? null : await AsyncStorage.setItem("is_user", payload.is_user.toString());
            payload.is_therapist === undefined ? null : await AsyncStorage.setItem("is_therapist", payload.is_therapist.toString());
            payload.status === undefined ? null : await AsyncStorage.setItem("status", payload.status);
            payload.bio === undefined ? null : await AsyncStorage.setItem("bio", payload.bio);
            ////await AsyncStorage.setItem("is_staff", payload.is_staff); //.toString()
            //await AsyncStorage.setItem('AUTH_TOKEN', payload.token); // This one used to auto-loggin init
            payload.token === undefined ? null : console.log('LogginStorage succesfull')
            // await AsyncStorage.setItem("token", payload.token);
            // await AsyncStorage.setItem("userpk", payload.pk.toString());
            // await AsyncStorage.setItem("username", payload.username);
            // await AsyncStorage.setItem("name", payload.name);
            // await AsyncStorage.setItem("avatar", payload.avatar);
            // await AsyncStorage.setItem("is_user", payload.is_user.toString());
            // await AsyncStorage.setItem("is_therapist", payload.is_therapist.toString());
            // await AsyncStorage.setItem("status", payload.status);
            // await AsyncStorage.setItem("bio", payload.bio);
            // ////await AsyncStorage.setItem("is_staff", payload.is_staff); //.toString()
            // await AsyncStorage.setItem('AUTH_TOKEN', payload.token); // This one used to auto-loggin init
            // console.log('LogginStorage succesfull')
        }catch(error){
            console.log(error, 'error at logginStorage')
        }
    }),

    setAuthdata: action((state, payload) => {
        //console.log(payload, 'SETAUTHHH MODEL')
        state.userpk = payload.userpk === undefined ? state.userpk : payload.userpk;
        state.userpk = payload.pk === undefined ? state.userpk : payload.pk;

        state.username = payload.username === undefined ? state.username : payload.username;
        state.name = payload.name === undefined ? state.name : payload.name;
        state.avatar = payload.avatar === undefined ? state.avatar : payload.avatar;
        state.is_user = payload.is_user === undefined ? state.is_user : payload.is_user;
        state.is_therapist = payload.is_therapist === undefined ? state.is_therapist : payload.is_therapist;
        state.status = payload.status === undefined ? state.status : payload.status;
        state.bio = payload.bio === undefined ? state.bio : payload.bio;
        state.token = payload.token === undefined ? state.token : payload.token;
        
        if (payload.token !== undefined){
            console.log('actualizando Header con token: ', payload.token)
            state.AuthConfig = {
                headers: {Authorization: 'Token ' + payload.token},
            } 
            console.log('autthotheader?', state.AuthConfig) 
        }

        if (payload.is_user !== undefined){
        switch('true'){ // En este caso, se guardó como "true", no true
            case state.is_user.toString(): // el .toString es porke en Loggin viene true y checktoken 'true', así iguala
                state.typeuser = 'patient'      
                break
            case state.is_therapist.toString():
                state.typeuser = 'therapist'
                break
          }
          console.log('Updated Type Of User', state.typeuser)
        }

    }),

    setData: action((state, payload) => {
       
        //state.userpk = payload.userpk === undefined ? state.userpk : payload.userpk;

        state.therapistPk = payload.therapistPk === undefined ? state.therapistPk : payload.therapistPk;
        state.therapistName = payload.therapistName === undefined ? state.therapistName : payload.therapistName;
        state.therapistUsername = payload.therapistUsername === undefined ? state.therapistUsername : payload.therapistUsername;
        state.therapistAvatar = payload.therapistAvatar === undefined ? state.therapistAvatar : payload.therapistAvatar;
        state.therapistAccepted = payload.therapistAccepted === undefined ? state.therapistAccepted : payload.therapistAccepted;
        
        state.dataPatients = payload.dataPatients === undefined ? state.dataPatients : payload.dataPatients;
        state.selectedPatient = payload.selectedPatient === undefined ? state.selectedPatient : payload.selectedPatient;

        state.notifications = payload.notifications === undefined ? state.notifications : payload.notifications;
     
    }),

}




export default STORE