import { useNavigation } from '@react-navigation/native';
import { useStoreState } from 'easy-peasy';
import React, { useContext } from 'react'
import { View, Text, StyleSheet, ScrollView, FlatList, Dimensions, TouchableOpacity } from 'react-native'
// import { Calendar, CalendarList, ExpandableCalendar, Timeline, WeekCalendar } from 'react-native-calendars';
import { Avatar, Button } from 'react-native-elements';
import  SetAppointment  from '../../components/SetAppointment';
import {Icon} from 'react-native-elements';
import { API_URL } from '../../core/api';
import axios from 'axios';
import moment from 'moment';
import Chart from '../../components/Chart';
moment.locale('es')

const {width} = Dimensions.get('window')

const PatientTherapistScreen = (props) => {

    const navigation = useNavigation()
    const data = useStoreState(state => state.selectedPatient)
    console.log('ERROR LOCALE', moment(data.therapylink.met_at).toLocaleString("es-ES"))
    const Config = useStoreState(state => state.AuthConfig)
    //const startTherapy = new Date(data.therapylink.met_at).toLocaleDateString("es-ES", { /* weekday: 'long', */ year: 'numeric', month: 'long', day: 'long' });
    const startTherapy = moment(data.therapylink.met_at).local().format('DD [de] MMM [del] YYYY')
    

    const d = {pk:data.pk}

    function handleDelete(e) {
      axios.delete(API_URL + 'therapylink/'+data.therapylink.id+'/delete/', Config)
      .then(navigation.navigate('DashTherapistStackScreen'))
      .catch(error => console.log(error))
    }
    function handleAccept(e) {
      axios.put(API_URL + 'therapylink/'+data.therapylink.id+'/accept/', {acceptance: 1}, Config)
      .then(navigation.navigate('DashTherapistStackScreen'))
      .catch(error => console.log(error))
    }


    return (
      <View style={{flex:1}}>
        <View style={{ flex:1, alignItems:'center'}}>
          <View style={styles.view}>
          <Avatar source={ { uri: data.avatar}} rounded={true} size={120} />
            <View style = {{padding:25}}>
              <Text style={{fontSize:22, fontWeight:'bold'}}>{data.name}</Text>
              <Text style={{fontSize:16, fontWeight:'bold', color:'#3e5ba6'}}>{data.username}{"\n"}</Text>
  {data.therapylink.accepted ? (<Text style={{fontSize:11}}>En terapia desde: {startTherapy}</Text>) : null}
            </View>
          </View>
          

 
  {data.therapylink.accepted ? (
          <ScrollView >
          {/* <View style={{top: 20}} > */}
          <View>

            <View style={{paddingVertical:20, marginVertical:20, backgroundColor:'white'}}>
            <Chart patient_pk = {data.pk}  />
            </View>

            <SetAppointment user_id={data.therapylink.user_id} therapist_id={data.therapylink.therapist_id}/>
          </View>
          </ScrollView>
          ) : (<><View style={{padding:15}}>
          <Text style={{fontSize:16}}>Este usuario ha contactado con usted. ¿Es paciente suyo?{"\n"}</Text>
          <View style={{flexDirection:"row"}} >
              <TouchableOpacity
                onPress={() => handleAccept()}> 
                <View style={{ backgroundColor: 'green', borderRadius: 30, margin: 5, flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding : 10 }}>
                    <Text style={{color:"white", fontSize:14, fontWeight:"bold"}}>Aceptar a {data.name}</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => handleDelete()}> 
                <View style={{ backgroundColor: 'red', borderRadius: 30, margin: 5, flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 10 }}>
                    <Text style={{color:"white", fontSize:14, fontWeight:"bold"}}>Rechazar</Text>
                </View>
              </TouchableOpacity>
          </View>
          </View></>)}
        </View>


  {data.therapylink.accepted ? (
        <View style = {{flexDirection:'row', justifyContent:'center', }}>
          <TouchableOpacity
              // onPress={() => { navigation.navigate('ChatTherapistStackScreen', {screen: 'ChatTherapistScreen', params: {d}}); }}> 
              onPress={() => { navigation.navigate('ChatPatientTherapistScreen', {d}); }}> 
              <View style={{ backgroundColor: '#3e5ba6', borderRadius: 30, margin: 5, flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingRight : 25 }}>
                  <Icon
                  reverse
                  name='message-text-outline'
                  type='material-community'
                  color='#3e5ba6'
                  />
                  <Text style={{color:"white", fontSize:14, fontWeight:"bold"}}>Chat con {data.name}</Text>
              </View>
              </TouchableOpacity>
          </View>
     ) : null}

        </View>  
    )
}

const styles = StyleSheet.create({

  view: {
      height:180,
      width: width-20 ,
      margin : 5,
      backgroundColor:"white",
      borderRadius:15,
      elevation:10,
      padding:15,
      flexDirection:'row',

  },

});
export default PatientTherapistScreen
