import React, { useEffect, useState }from "react";
import { TouchableOpacity, StyleSheet, Image, Text, View, useWindowDimensions, Animated, Easing, ScrollView, Linking} from "react-native";
import './styles.css';
import logo from '../../../assets/LogoPSYKEHUBlowr.png'
import bandas from '../../../assets/bandasLogo.png'
import bandasR from '../../../assets/bandasLogoR.png'
import divan from '../../../assets/divandigital.png'
import googleplay from '../../../assets/googlestore.png'
import applestore from '../../../assets/applestore.png'
import { Icon } from "react-native-elements";
import { render } from "react-dom";
import { useNavigation } from "@react-navigation/native";
import Features from "./Features";
import Footer from "../components/Footer";

// Único Screen fuera de la carpeta screens 
// Así en "/" path está la app en path raíz :)

function LandingPageContent() {
    const navigation = useNavigation()
    console.log('NAAAVVV???', navigation)
    const {width, height} = useWindowDimensions()
    const [isLeftSide, setIsLeftSide] = useState(true)
    const [springValue, setSpringValue] = useState(new Animated.Value(0.3))
    const [levitatingValue, setLevitatingValue] = useState(new Animated.Value(0.3))


    function spring () {
        springValue.setValue(0.7)  // 0.3 example
        Animated.spring(
            springValue,
            {
                toValue: 1,
                friction: 1,
                // easing: Easing.linear
            }
        ).start()
        ////// setTimeout(() => spring(), 6000)
    }
    
    function levitatingL () {
        levitatingValue.setValue(0);
        Animated.timing(
            levitatingValue,
        {
            toValue: 1,
            duration: 3000,
            easing: Easing.linear
        }
        ).start()
        ////setTimeout(() => levitatingR(), 3000),
        setIsLeftSide(false)
    }
    function levitatingR () {
        levitatingValue.setValue(0);
        Animated.timing(
            levitatingValue,
        {
            toValue: 1,
            duration: 3000,
            easing: Easing.linear
        }
        ).start()
        setTimeout(() => levitatingL(), 3000),
        setIsLeftSide(true)
    }
    const marginLeft = levitatingValue.interpolate({
        inputRange: [0, 1],
        outputRange: isLeftSide == true ? [-50, 0] : [0, -50],
      })
      const marginHorizontal = levitatingValue.interpolate({
        inputRange: [0, 1],
        outputRange: isLeftSide == true ? [-35, 0] : [0, -35],
      })
    const marginRight = levitatingValue.interpolate({
        inputRange: [0, 1],
        outputRange: isLeftSide == true ? [0, 20] : [20, 0],
      })

    useEffect(() => levitatingL(), [])
    useEffect(() => spring(), [])

 

    const ComponentText = () => {
        return(<>
                {/* <View style={width<1600?({width:width, alignItems:'center'}):({width: width/2, alignItems:'center'})}> */}
                <View style={width<1600?({width:width, alignItems:'center'}):({width: width/2, alignItems:'center'})}>
                <View style={{borderRadius:20, padding:50, marginTop:200, marginBottom:20, marginHorizontal:200, backgroundColor:'rgba(255, 255, 255, 0.3))'}}>
                    {/* <Image source={logo} style={stylesLanding.imageLogo} /> */}
                    <Text style={{color: '#3e5ba6', fontSize: width>500?80:60}}> PSYKE<Text style={{fontWeight:"bold"}}>HUB</Text></Text>
                    <Text style={styles.h2}> {'\n'}{'\n'}PsykeHub es una plataforma de salud donde terapeutas y pacientes sincronizan la actividad de su terapia registrando el progreso de su mente (<Text style={{fontWeight:'bold', color:'#3e5ba6'}}>psyke</Text>) en un repositorio privado online (<Text style={{fontWeight:'bold', color:'#3e5ba6'}}>hub</Text>).{"\n"}{"\n"}</Text>
                    <Text style={[styles.h2, {fontWeight:'bold', color:'#3e5ba6'}]}>¡Únete ya a la revolución digital del cuidado psicológico!{"\n"}{"\n"}</Text>
                {/*  LOGOS ANDROID IOS etc */}
                    <View style={{flexDirection:width>578?'row':'column', alignItems:'center'}} >
                        {/* <TouchableOpacity style={{ flexDirection:'row', backgroundColor: '#222222', width: 150, alignItems:'center', borderRadius: 10, padding: 10, margin: 10 }}
                                        onPress={() => { props.navigation.navigate("SignIn")}}>
                                        <Image source={googleplay} style={{width:30, height:30, marginHorizontal:10}} />
                                        <Text style={{ color: '#FFF', fontSize: 12 , marginVertical:'auto' }}> {/* 20 y sin marginvertical }}> 
                                            Próximamente
                                        </Text>
                                    </TouchableOpacity> Android */}
<a  href='https://play.google.com/store/apps/details?id=pack.PsykeHub&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Disponible en Google Play' style={{width:200}} src='https://play.google.com/intl/es-419/badges/static/images/badges/es_badge_web_generic.png'/></a>

                        {/* <TouchableOpacity style={{ flexDirection:'row', backgroundColor: '#000', width: 150, alignItems:'center', borderRadius: 10, padding: 10, margin: 10 }}
                                        onPress={() => { props.navigation.navigate("SignIn")}}>
                                            <Image source={applestore} style={{width:30, height:30, marginHorizontal:10}} />
                                        <Text style={{ color: '#FFF', fontSize: 12, marginVertical:'auto' }}> 
                                            Próximamente
                                        </Text>
                                    </TouchableOpacity> */}{/* iOS */}
                        <TouchableOpacity style={{ flexDirection:'row', backgroundColor: '#000', width: 200, alignItems:'center'/* paddingHorizontal: 40 */, borderRadius: 10, padding: 1, margin: 10}}
                                        onPress={() => { navigation.navigate("Authentication")}}>
                                        <Icon color="#FFFFFF" name="browser" type="octicon" size={45} style={{ marginHorizontal:5}} />
                                        <Text style={{ color: '#FFF', fontSize: 17, fontWeight:'bold', marginHorizontal:'auto' }}>
                                            Navegador Web
                                        </Text>
                                    </TouchableOpacity>
                    </View>
                    <View style={{flexDirection:'row'}}>
                        <Text style={{ color: '#000', fontSize: 14, marginVertical:'auto'}}> 
                            Próximamente disponible en Apple Store</Text>
                        <Image source={applestore} style={{width:15, height:15, marginHorizontal:2, tintColor:'black'}} />
                    </View>
                </View>
                </View>
                
        </>)
    }

    const ComponentLogos = () => {
        return(
        <View style={[width<1600?({ width:width, alignItems:'center'}):({ alignItems:'center'}), {marginVertical:40}]}>
                <Animated.Image style={{ width: width>578?820:500, height: width>578?400:250, top:200, position: "absolute", filter:"drop-shadow(-2px 2px 20px rgba(0, 0, 0, 0.2))"}} source={divan} />
                <Animated.Image  style={{width:width>578?600:400, height:width>578?360:240,  transform: [{scale: springValue}], filter:"drop-shadow(-2px 2px 20px rgba(0, 0, 0, 0.2))" }}
                        source={logo} />
            </View>
        )}



        // Aqui montamos tanto la imagen como el texto en el paso intermedio
    return(<>

<View style={styles.container}>
            {/* <View style={{flex:2}}></View> */}

<Animated.Image  style={{ marginLeft, width: 150, height: 100, bottom:10, position: "absolute", filter:"drop-shadow(-2px 2px 15px rgba(0, 0, 0, 0.15))"}} source={bandas} />
<Animated.Image  style={{ marginRight, width: 150, height: 100, top:10, right:10, position: "absolute", filter:"drop-shadow(-2px 2px 15px rgba(0, 0, 0, 0.15))"}} source={bandasR} />
            
            {width<1600? (
            <View style={{justifyContent:'center'}} >
                <ComponentLogos />
                <ComponentText />   
                 
            </View>
            ):(
            <View style={{flexDirection:'row'}} >
                <ComponentText />
                <ComponentLogos />
            </View>
            )}

            {/* <View style={{flex: 1}}></View> */}
            <View style= {{flexDirection:'row', marginBottom:50, marginLeft:width<1600? null:'auto', marginHorizontal:width<1600? 'auto':null,  marginRight: width<1600? null:300 }}>
                <Text onPress={() => window.location = '/avisolegal'} style={{marginHorizontal:20}}>Aviso legal</Text>
                <Text onPress={() => window.location = '/politicadeprivacidad'} style={{marginHorizontal:20}}>Política de privacidad</Text>
                <Text onPress={() => window.location = '/politicadecookies'} style={{marginHorizontal:20}}>Política de cookies</Text>
            </View>
    </View>
    </>)
}

const styles = StyleSheet.create({
    background: {
        backgroundColor: '#FFF',
        // width: width,
        // height: height,
    },

    container: {
        flex: 1,
        // width:width
        // backgroundColor: '#FFFFFF',
        flexWrap:'wrap',
        //backgroundColor: "radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%)"
      //   alignItems: 'center',
      //   justifyContent: 'center',
      },
      h1: {
          color: '#3e5ba6',
          fontSize: 80,
        },
      h2: {
          color: '#333333',
          fontSize: 20,
      },
      imageLogo: {
          width: 600,
          height: 360,
        //   justifyContent: 'center',
        },
      imageBandasLeft: {
          width: 150,
          height: 100,
          bottom:10,
          position: "absolute",
      },
      imageBandasRight: {
          width: 150,
          height: 100,
          top:10,
          right:0,
          position: "absolute",
      },
      topContainer: {
          flex:1,
          justifyContent:'center',
          alignItems:'center',
        },
      middleContainer: {
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        },
      divan: {
        width: 1220,
        height: 760,
        position: "absolute",
    },
})

function LandingPage(){
    const {width, height} = useWindowDimensions()
    // if(width > 1024){
    if(width > 1600){
    return(
        <>
        {/* <LandingPageContent /> */}
        {/* <ScrollView > */}
        <ScrollView>
        <View style={{height:height}} >
            <LandingPageContent />
        </View>
            <Features />
        {/* </ScrollView> */}
        <Footer />
        </ScrollView>
        </>
    )
    }else {
    return(<>
        <ScrollView style={{width:width}}>
        <LandingPageContent />
        <Features />
        <Footer />
        </ScrollView>
    </>)
    }

}

export default LandingPage;