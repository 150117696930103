import { useStoreState } from 'easy-peasy';
import React, {useState} from 'react'
import { View, Text, StyleSheet, TouchableHighlight, TextInput, useWindowDimensions, Image, ScrollView } from 'react-native'
import moment from 'moment';
import { LinearGradient } from 'expo-linear-gradient';
import eventrecord from '../../assets/EventRecord.png'
import { Rating} from 'react-native-elements';

import None from '../../assets/SAM/None.png'
import P1 from '../../assets/SAM/P1.png'
import P2 from '../../assets/SAM/P2.png'
import P3 from '../../assets/SAM/P3.png'
import P4 from '../../assets/SAM/P4.png'
import P5 from '../../assets/SAM/P5.png'
import D1 from '../../assets/SAM/D1.png'
import D2 from '../../assets/SAM/D2.png'
import D3 from '../../assets/SAM/D3.png'
import D4 from '../../assets/SAM/D4.png'
import D5 from '../../assets/SAM/D5.png'
import A1 from '../../assets/SAM/A1.png'
import A2 from '../../assets/SAM/A2.png'
import A3 from '../../assets/SAM/A3.png'
import A4 from '../../assets/SAM/A4.png'
import A5 from '../../assets/SAM/A5.png'
import axios from 'axios';
import { API_URL } from '../core/api';
import { useNavigation } from '@react-navigation/native';


const EventRecord = (props) => {
    // console.log('eventappointment component', divan)
    const route = props.route
    //console.log('PROPS', route.params.item)
    const data = route.params.item
    const {width} = useWindowDimensions();
    const Config = useStoreState(state => state.AuthConfig)
    //const isTherapist = useStoreState(state => state.is_therapist)
    const typeuser = useStoreState(state => state.typeuser);
    //console.log('typeuseeer', typeuser)
    const navigation = useNavigation()

    moment.locale('es')
    let fecha_origin = moment(route.params.item.appointment_at).local().format('DD [de] MMM [a las] hh:mm A');
    // console.log('Fecha en local: ', fecha_origin);

    const [patientcomment, setPatientcomment] = useState(data.patient_comment);
    const [therapistcomment, setTherapistcomment] = useState(data.therapist_comment);
    const [therapistgoal, setTherapistgoal] = useState(data.therapist_goal);
    const [pl, setPl] = useState(data.pleasure);
    const [ar, setAr] = useState(data.arousal);
    const [dm, setDm] = useState(data.dominance);
    const [anychange, setAnychange] = useState(false);
    const [savedchange, setSavedchange] = useState('');
    

    const imageP = {
      null: None,
      0: None,
      1: P1,
      2: P2,
      3: P3,
      4: P4,
      5: P5
    }
    const imageA = {
      null: None,
      0: None,
      1: A1,
      2: A2,
      3: A3,
      4: A4,
      5: A5
    }
    const imageD = {
      null: None,
      0: None,
      1: D1,
      2: D2,
      3: D3,
      4: D4,
      5: D5
    }

    function updateAppointmentPatient () {
      const evaluation = {patient_comment: patientcomment, pleasure: pl, arousal: ar, dominance: dm}
      axios.put(API_URL+'appointments/'+data.pk+'/edit/', evaluation, Config)
      .then(setAnychange(false), setSavedchange('Evaluaciones actualizadas'), data.patient_comment = patientcomment,
        console.log(navigation)
      )
    }
    function updateAppointmentTherapist () {
      const evaluation = {therapist_comment: therapistcomment, therapist_goal: therapistgoal}
      axios.put(API_URL+'appointments/'+data.pk+'/edit/', evaluation, Config)
      .then(setAnychange(false), setSavedchange('Información actualizada'), data.therapist_comment = therapistcomment, data.therapist_goal = therapistgoal, 
      navigation.navigate('PatientCalendarTherapistScreen')
      //console.log(navigation)
      )
    }

  return (
    <ScrollView style={{backgroundColor:'white'}}>
    <View style={{
        flex: 1, backgroundColor:'white', alignItems: 'center', width: width>768?width*0.8:"100%", margin:"auto"
    }}>
        
        <LinearGradient
    // Linear Gradient  - In PRO put picture
    //colors={['#3e5ba6', '#444cb3', '#843361'] }
            /////colors={['#2363a3', '#343556', '#6b3b4e']}//
            colors={['#2363a3', '#3e5ba6', '#6e3c64']}//
    style={{ width:"100%"}}
    >
      <View style={{width:'100%', alignItems:'center'}}>
        {/* <Image source={divan} width={200} height={300} /> */}
        <Image source={eventrecord} style={{width:350, height:180}} />
        <Text>{"\n"}</Text>
      </View>
      </LinearGradient>
      <View style={{width:"100%", flex:1, backgroundColor:'white',
      paddingTop: 50,
      backgroundColor: '#FFFFFF',
      borderTopRightRadius: 30,
      borderTopLeftRadius: 30,
      top: -30,
      alignItems: "center"}}>

      <View>
        <Text style={[styles.customTitle, {color:'#843361'}]}> Evento registrado: {fecha_origin} {"\n"}</Text>
        <Text><Text  style={styles.customTitle}>{"\n"} Descriptión de lo sucedido:</Text> </Text>
        <Text>{data.event_description}</Text>
        <Text><Text  style={styles.customTitle}>{"\n"} Contexto de la situación:</Text> </Text>
        <Text>{data.event_context}</Text>
        <Text><Text  style={styles.customTitle}>{"\n"} Solución o estrategia:</Text> </Text>
        <Text>{data.event_solution}</Text>
        <Text><Text  style={[styles.customTitle, {color:'#843361', paddingTop:20}]}>{"\n"}Emociones y sensaciones:{"\n"}</Text> </Text>
        <Text style={{fontWeight:'bold', color:'#F43E3E'}}>Valencia afectiva:</Text>

        <View style={{flexDirection:'row'}}>
          <Rating
            type='custom'
            //ratingImage={WATER_IMAGE}
            ratingImage={null}
            ratingColor='#3e5ba6'//'#3498db'
            ratingBackgroundColor='#c8c7c8'
            ratingCount={5}
            minValue = {1}
            startingValue = {pl}
            imageSize={30}
            showRating
            readonly
            style={{padding:2}}
            onFinishRating = {(value) => {setPl(value), setAnychange(true)}}
          />
          <Image source={imageP[pl]} style={{width:100, height:100}} />
        </View>

        <Text style={{fontWeight:'bold', color:'#96BC5C'}}>Activación o arousal:</Text>

        <View style={{flexDirection:'row'}}>
          <Rating
            type='custom'
            //ratingImage={WATER_IMAGE}
            ratingImage={null}
            ratingColor='#3e5ba6'//'#3498db'
            ratingBackgroundColor='#c8c7c8'
            ratingCount={5}
            minValue = {1}
            startingValue = {ar}
            imageSize={30}
            showRating
            readonly
            style={{padding:2}}
            onFinishRating = {(value) => {setAr(value), setAnychange(true)}}
            // in case of therapist: readonly
          />
          <Image source={imageA[ar]} style={{width:100, height:100}} />
        </View>

        <Text style={{fontWeight:'bold', color:'#AB7854'}}>Dominancia:</Text>

        <View style={{flexDirection:'row'}}>
          <Rating
            type='custom'
            //ratingImage={WATER_IMAGE}
            ratingImage={null}
            ratingColor='#3e5ba6'//'#3498db'
            ratingBackgroundColor='#c8c7c8'
            ratingCount={5}
            minValue = {1}
            startingValue = {dm}
            imageSize={30}
            showRating
            readonly
            style={{padding:2}}
            onFinishRating = {(value) => {setDm(value), setAnychange(true)}}
            // in case of therapist: readonly
          />
          <Image source={imageD[dm]} style={{width:100, height:100}} />
        </View>
        
        <Text style={{paddingTop:15}}><Text  style={styles.customTitle}> Sensaciones experimentadas:  </Text> </Text>
        <Text>{data.event_feeling}</Text>
      </View>
      
      </View>
    </View>
    </ScrollView>

    )


}

export default EventRecord



const styles = StyleSheet.create({
    customTitleContainer: {
      flexDirection: 'row', 
      alignItems: 'center', 
      padding: 10
    },
    customTitle: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#3e5ba6'
      // color: '#00BBF2'
    }
  });