import {
    Text,
    Platform,
    StyleSheet,
    TouchableOpacity,
} from 'react-native';
import React, { Component } from 'react';
import { useNavigation } from '@react-navigation/native';
//import i18n from '../services/i18n';
const AddButton = () => {
    // static defaultProps = {
    //     style: {},
    // }
    const navigation = useNavigation()
        return (
            <TouchableOpacity
                onPress={() => navigation.navigate('NewEventRecordPatientStackScreen')}
                style={[styles.addButton]}
            >
                <Text style={styles.addButtonText}>+</Text>
            </TouchableOpacity>
        );
}
const styles = StyleSheet.create({
    addButton: {
        width: 50,
        height: 50,
        // ...i18n.select({
        //     rtl: { paddingEnd: 1 },
        //     ltr: { paddingStart: 1 },
        // }),
        position: 'absolute',
        right:10,
        bottom:10,
        paddingBottom: 3,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 25,
        backgroundColor: Platform.OS === 'ios' ? '#3e5ba6' : '#3e5ba6', //'#0076FF' : '#2962FF',
        ...Platform.select({
            ios: {
                shadowOpacity: 0.3,
                shadowRadius: 1,
                shadowColor: 'black',
                shadowOffset: { width: 0, height: 1 },
            },
            android: {
                elevation: 2,
            }
        })
    },
    addButtonText: {
        fontSize: 30,
        color: 'white',
        textAlign: 'center',
    },
});
export default AddButton;