import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";
import CalendarPatientScreen from '../../patient/CalendarPatientScreen';
import AppointmentPatientScreen from '../../patient/AppointmentPatientScreen';
import RecordPatientScreen from '../../patient/RecordPatientScreen';
import NotificationChatPatient from '../../../components/NotificationChatPatient';
import AvatarNavHeader from '../../../components/AvatarNavHeader';

const CalendarPatientStack = createStackNavigator();
const CalendarPatientStackScreen = ({ navigation }) =>{
  return(
    <CalendarPatientStack.Navigator>
      <CalendarPatientStack.Screen
        name={"CalendarPatientScreen"}
        component={CalendarPatientScreen}
        options={{
          title: "Citas",
          headerLeft : () => {
            return <AvatarNavHeader />
          },
          headerRight : () => {
            return (
              <NotificationChatPatient />
            )
          },
        }}
      />
      <CalendarPatientStack.Screen
        name={"AppointmentPatientScreen"}
        component={AppointmentPatientScreen}
        options={{
          title: "Cita en el calendario",
        }}
    />
    <CalendarPatientStack.Screen
        name={"RecordPatientScreen"}
        component={RecordPatientScreen}
        options={{
          title: "Registro en el calendario",
        }}
    />
      {/* <CalendarPatientStack.Screen
        name={"Detalles de Calendar Screen"}
        component={Detalles de Calendar Screen}
        options={{
          title: "Editar perfil",
        }}
    /> */}
    </CalendarPatientStack.Navigator>
  )
}
export default CalendarPatientStackScreen