import isEmpty from 'lodash/isEmpty';
import React, {Component, useCallback, useEffect, useState} from 'react';
import {Platform, StyleSheet, Alert, View, Text, Image, TouchableOpacity, Button, Dimensions} from 'react-native';
import {ExpandableCalendar, AgendaList, CalendarProvider, WeekCalendar} from 'react-native-calendars';
//import { getConfig } from '../core/config';
import axios from 'axios';
import { API_URL } from '../core/api';
import { useStoreState } from 'easy-peasy';
import { useNavigation, useIsFocused } from '@react-navigation/native';
//import testIDs from '../testIDs';
import leftArrow from '../../assets/SAM/D5.png'

const { width, height } = Dimensions.get('window');

import { LocaleConfig } from 'react-native-calendars';

////// pt-br localization for react-native-calendars
LocaleConfig.locales['es-Es'] = {
  monthNames: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  monthNamesShort: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul.',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dec',
  ],
  dayNames: [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ],
  dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sáb'],
  today: 'Hoy',
};
LocaleConfig.defaultLocale = 'es-Es';

export { LocaleConfig };


const themeColor = '#3e5ba6';
const lightThemeColor = '#EBF9F9';


// type MarkedDate = {
//   [key: string]: object;
// }

// function getMarkedDates(items: any[]) {
//   const marked: MarkedDate = {};
// 
//   items.forEach(item => {
//     // NOTE: only mark dates with data
//     if (item.data && item.data.length > 0 && !isEmpty(item.data[0])) {
//       marked[item.title] = {marked: true};
//     } else {
//       marked[item.title] = {disabled: true};
//     }
//   });
//   return marked;
// }

// const leftArrowIcon = require('../img/previous.png');
// const rightArrowIcon = require('../img/next.png');

// interface Props {
//   weekView?: boolean;
// }

const ExpandableCalendarScreen = (props) => {
console.log('ExpandableCalendar rerun')
const isFocused = useIsFocused()
const [events, setEvents] = useState([])
const [busy, setBusy] = useState(true)

useEffect(async () => {   //// Este usefect va a buscar las citas cada vez que se ponga focus en el calendario. Así al terminar de cambiar una cita ya se actualliza cada render
  setBusy(true);
  var buildata = [];
  const GetAppointments = async (userpk, Config) => {
    // const Config = await getConfig()
   
    await axios.get(API_URL+'appointments/'+userpk+'/listbyuser/', Config)
      .then(res => {
        //var options = { weekday: 'long', year: 'long', month: 'long', day: 'long' };
        for (var i = 0, max = res.data.length; i < max; i += 1) {
          if (res.data[i].appointment_at != null) {
            buildata.push({      
              date: new Date(res.data[i].appointment_at).toISOString().split('T')[0],
              title: new Date(res.data[i].appointment_at).toISOString(),
              data: [{
                type: 'appointment',
                duration: '1h',
                title: "Cita con su terapeuta",
                hour: new Date(res.data[i].appointment_at).toLocaleTimeString(),
                appointment_at: res.data[i].appointment_at,
                created_at: res.data[i].created_at,
                pk: res.data[i].pk,
                therapist_id: res.data[i].therapist_id,
                therapist_name: res.data[i].therapist_name,
                user_id: res.data[i].user_id,
                user_name: res.data[i].user_name,
                patient_comment: res.data[i].patient_comment,
                therapist_comment: res.data[i].therapist_comment,
                therapist_goal: res.data[i].therapist_goal,
                pleasure: res.data[i].pleasure,
                arousal: res.data[i].arousal,
                dominance: res.data[i].dominance
              }]
            } )
          }//console.log('itemAppointment in Calendar', res.data[i])
        }
  
        // const marked: MarkedDate = {};
        const marked = {};
        buildata.forEach(item => {
          // NOTE: only mark dates with data
          console.log(item, 'item?')
          if (item.data && item.data.length > 0 && !isEmpty(item.data[0])) {
            marked[item.date] = {marked: true};
          } else {
            marked[item.date] = {disabled: true};
          }
        });
        ////this.setState({appointments : buildata})
       ////////// setAppointments(buildata)
       //////// setBusy(false)
        //console.log('appointments got')
      })
    .catch( error => {
        console.log(error)
    })
  };  
  

  const GetEventRecords = async (userpk, Config) => {
    await axios.get(API_URL+'eventrecords/'+userpk+'/listbyuser/', Config)
      .then(res => {
        //var buildata = [];
        //var options = { weekday: 'long', year: 'long', month: 'long', day: 'long' };
        for (var i = 0, max = res.data.length; i < max; i += 1) {
          if (res.data[i].created_at != null) {
            buildata.push({
              
              date: new Date(res.data[i].created_at).toISOString().split('T')[0],
              title: new Date(res.data[i].created_at).toISOString(),
              data: [{
                type: 'eventrecord',
                //duration: '1h',
                title: "Evento registrado",
                hour: new Date(res.data[i].created_at).toLocaleTimeString(),
                created_at: res.data[i].created_at,
                pk: res.data[i].pk,
                user_id: res.data[i].user_id,
                user_name: res.data[i].user_name,
                event_description: res.data[i].event_description,
                event_context: res.data[i].event_context,
                event_solution: res.data[i].event_solution,
                event_feeling: res.data[i].event_feeling,
                pleasure: res.data[i].pleasure,
                arousal: res.data[i].arousal,
                dominance: res.data[i].dominance
              }]
            } )
          }//console.log('itemEvent in Calendar', res.data[i])
        }
  
        // const marked: MarkedDate = {};
        const marked = {};
        buildata.forEach(item => {
          // NOTE: only mark dates with data
          console.log(item, 'item?')
          if (item.data && item.data.length > 0 && !isEmpty(item.data[0])) {
            marked[item.date] = {marked: true};
          } else {
            marked[item.date] = {disabled: true};
          }
        });
        ////this.setState({appointments : buildata})
     /////////////////   setAppointments(buildata)
    ////////////    setBusy(false)
       // console.log('eventrecord got')
      })
    .catch( error => {
        console.log(error)
    })
  };  
  
// const buildata = 
await GetAppointments(props.userpk, props.Config);
await GetEventRecords(props.userpk, props.Config) /// Await los GET y luego ya displayeas el buildata 
// AQUI ORDENAR POR FECHAS
setEvents(buildata)
setBusy(false)
} // console.log('buildd', buildata)}
, [isFocused])




console.log(isFocused)
return(<>  
{busy?(<Text>Cargando información del calendario</Text>):(<><ExpandableCalendarScreenclass  events = {events}   style={{flex:1}} />
  {//console.log('cualesson los app', appointments)
  }</>)}
</>)
}
export default ExpandableCalendarScreen


//export default class ExpandableCalendarScreen extends Component {
class ExpandableCalendarScreenclass extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      events : this.props.events,
    };
  }

  

  //  marked = getMarkedDates(ITEMS);
  theme = getTheme();
  todayBtnTheme = {
    todayButtonTextColor: themeColor
  };

  onDateChanged = (/* date, updateSource */) => {
    // console.warn('ExpandableCalendarScreen onDateChanged: ', date, updateSource);
    // fetch and set data for date + week ahead
  };

  onMonthChange = (/* month, updateSource */) => {
    // console.warn('ExpandableCalendarScreen onMonthChange: ', month, updateSource);
  };

  // renderItem = ({item}: any) => {
  renderItem = ({item}) => {
    return( <AgendaItem item={item}/>)
  };
  




//   GetAppointments (userpk, Config){
//     // const Config = await getConfig()
   
//     axios.get(API_URL+'appointments/'+userpk+'/listbyuser/', Config)
//       .then(res => {
//         var buildata = []; var buildmark = [];
//         var options = { weekday: 'long', year: 'long', month: 'long', day: 'long' };
//         for (var i = 0, max = res.data.length; i < max; i += 1) {
//           if (res.data[i].appointment_at != null) {
//             buildata.push({
//               date: new Date(res.data[i].appointment_at).toISOString().split('T')[0],
//               title: new Date(res.data[i].appointment_at).toISOString(),
//               data: [{
//                 duration: '1h',
//                 title: "Cita con su terapeuta",
//                 hour: new Date(res.data[i].appointment_at).toLocaleTimeString(),
//                 appointment_at: res.data[i].appointment_at,
//                 created_at: res.data[i].created_at,
//                 pk: res.data[i].pk,
//                 therapist_id: res.data[i].therapist_id,
//                 therapist_name: res.data[i].therapist_name,
//                 user_id: res.data[i].user_id,
//                 user_name: res.data[i].user_name,
//                 patient_comment: res.data[i].patient_comment,
//                 therapist_comment: res.data[i].therapist_comment,
//                 therapist_goal: res.data[i].therapist_goal,
//                 pleasure: res.data[i].pleasure,
//                 arousal: res.data[i].arousal,
//                 dominance: res.data[i].dominance
//               }]
//             } )
//           }console.log('itemAppointment in Calendar', res.data[i])
//           // var fecha = new Date();
// // var optionss = { year: 'numeric', month: 'long', day: 'numeric' };

//           //const d = new Date(res.data[i].appointment_at); console.log(d.toLocaleDateString('es-ES'), 'que es ')
//         // console.log(Date(fecha.toDateString("es-ES", optionss)).toLocaleDateString("es-ES"))
//         }

//         // const marked: MarkedDate = {};
//         const marked = {};
//         buildata.forEach(item => {
//           // NOTE: only mark dates with data
//           console.log(item, 'item?')
//           if (item.data && item.data.length > 0 && !isEmpty(item.data[0])) {
//             marked[item.date] = {marked: true};
//           } else {
//             marked[item.date] = {disabled: true};
//           }
//         });
//         this.setState({appointments : buildata})
//         this.setState({marked : marked})
//       })
//     .catch( error => {
//         console.log(error)
//     })
//   }  
  
  

  


  // componentDidMount(){
  //   this.setState({appointments: this.props.appointments})
  //   console.log('expandable mounted and appointments received')
  //   // this.GetAppointments(this.props.userpk, this.props.Config)
  //   // console.log('gathering appointments')
  // }  
       
  // reloaddata() {
  //   console.log('Render Calendar now')
  //   this.setState({reload : true})
  //   //this.GetAppointments(this.props.userpk, this.props.Config)
  //   console.log('gathering appointments in render')
  // }

  render() {
    console.log('render calendar', this.state.events)
    /////console.log(this.state.appointments.date, ' date4eee')
    // console.log('CHEKC DATE   ', this.state.appointments)
    // // console.log('CHECK ITEMS  ', ITEMS)
    // console.log(this.state.marked, 'markk')
    // var optionss = { year: 'numeric', month: 'long', day: 'numeric' };
    return (
      <View style={{height:"100%", backgroundColor:"white"}}>
      <CalendarProvider
        date = {new Date().toLocaleDateString()}
        //date={this.state.appointments.date}
        onDateChanged={this.onDateChanged}
        onMonthChange={this.onMonthChange}
        showTodayButton
        disabledOpacity={0.6}
        // theme={this.todayBtnTheme}
        // todayBottomMargin={16}
        key={LocaleConfig}
        style={{width:width>768?width*0.8:("100%"), backgroundColor:"white"}}
      >
        {this.props.weekView ? (
          // <WeekCalendar testID={testIDs.weekCalendar.CONTAINER} firstDay={1} markedDates={this.marked}/>
          <WeekCalendar  firstDay={1} /> //markedDates={this.marked}/>
        ) : (
          <ExpandableCalendar
            ////testID={testIDs.expandableCalendar.CONTAINER}
            // horizontal={false}
            // hideArrows
            // disablePan
            // hideKnob
            date = {new Date().toLocaleDateString()}
             initialPosition={ExpandableCalendar.positions.OPEN}
            // calendarStyle={styles.calendar}
            // headerStyle={styles.calendar} // for horizontal only
            // disableWeekScroll
             theme={this.theme}  // Cool!
            // disableAllTouchEventsForDisabledDays
            firstDay={1}
            markedDates={this.state.marked}

            renderArrow={(direction) => {
              if(direction === 'left') {
                  return <Text style={{fontSize:20, fontWeight:'bold'}}>{'<'}</Text>
              } else {
                  return <Text style={{fontSize:20, fontWeight:'bold'}}>{'>'}</Text>
              }
          }}
            // animateScroll
          />
        )}
        <AgendaList
          sections={this.state.events} //{}
          renderItem={this.renderItem}
          // scrollToNextEvent
          // sectionStyle={styles.section}
           dayFormat={'dddd, d MMMM'}
        />
      </CalendarProvider>
      {/* {this.state.appointments} */}
      </View>
    );
  }
}

// interface ItemProps {
//   item;
// }

const AgendaItem = React.memo(function AgendaItem(props) {
  const {item} = props;

  const buttonPressed = useCallback(() => {
    Alert.alert('Show me more');
  }, []);

  const itemPressed = useCallback(() => {
    Alert.alert(item.title);
  }, []);

  if (isEmpty(item)) {
    return (
      <View style={styles.emptyItem}>
        <Text style={styles.emptyItemText}>No Events Planned Today</Text>
      </View>
    );
  }
  const navigation = useNavigation()
  console.log('TYPO', item.type)
  switch(item.type){
    case 'appointment':
      return (
          <TouchableOpacity style={styles.itemAppointment} onPress={() => navigation.navigate('AppointmentPatientScreen', {item})}>
          {/* <TouchableOpacity onPress={itemPressed} style={styles.item} > */}
              <View>  
              <Text style={styles.itemHourText}>{item.hour}</Text>
              <Text style={styles.itemDurationText}>{item.duration}</Text>
            </View>
            <View style={{flexDirection:'column'}}>
            <Text style={styles.itemTitleText}>{item.title}</Text>
            <Text><Text style={{color: '#F43E3E'}}>Valencia:</Text> {item.pleasure} | <Text style={{color: '#96BC5C'}}>Arousal:</Text> {item.arousal} | <Text style={{color:'#AB7854'}}>Dominancia:</Text> {item.dominance}</Text>
            </View>
            <View style={styles.itemButtonContainer}>
              <Button color={'#3e5ba6'} title={'>'} onPress={() => navigation.navigate('AppointmentPatientScreen', {item})} />
            </View>
          </TouchableOpacity>
        );
    case 'eventrecord':
      return (
        <TouchableOpacity style={styles.itemEventRecord} onPress={() => navigation.navigate('RecordPatientScreen', {item})}>
        {/* <TouchableOpacity onPress={itemPressed} style={styles.item} > */}
            <View>  
            <Text style={styles.itemHourText}>{item.hour}</Text>
            <Text style={styles.itemDurationText}>{item.duration}</Text>
          </View>
          <View style={{flexDirection:'column'}}>
          <Text style={styles.itemTitleText}>{item.title}</Text>
          <Text><Text style={{color: '#F43E3E'}}>Valencia:</Text> {item.pleasure} | <Text style={{color: '#96BC5C'}}>Arousal:</Text> {item.arousal} | <Text style={{color:'#AB7854'}}>Dominancia:</Text> {item.dominance}</Text>
          </View>
          <View style={styles.itemButtonContainer}>
            <Button color={'#3e5ba6'} title={'>'} onPress={() => navigation.navigate('RecordPatientScreen', {item})} />
          </View>
        </TouchableOpacity>
      );
    default:
      return(null)
  }
  
});


function getTheme() {
  const disabledColor = 'grey';

  return {
    // arrows
    arrowColor: 'black',
    arrowStyle: {padding: 0},
    // month
    monthTextColor: 'black',
    textMonthFontSize: 16,
    textMonthFontFamily: 'HelveticaNeue',
    textMonthFontWeight: 'bold',
    // day names
    textSectionTitleColor: 'black',
    textDayHeaderFontSize: 12,
    textDayHeaderFontFamily: 'HelveticaNeue',
    textDayHeaderFontWeight: 'normal',
    // dates
    dayTextColor: themeColor,
    textDayFontSize: 18,
    textDayFontFamily: 'HelveticaNeue',
    textDayFontWeight: '500',
    textDayStyle: {marginTop: Platform.OS === 'android' ? 2 : 4},
    // selected date
    selectedDayBackgroundColor: themeColor,
    selectedDayTextColor: 'white',
    // disabled date
    textDisabledColor: disabledColor,
    // dot (marked date)
    dotColor: themeColor,
    selectedDotColor: 'white',
    disabledDotColor: disabledColor,
    dotStyle: {marginTop: -2}
  };
}


const styles = StyleSheet.create({
  calendar: {
    paddingLeft: 20,
    paddingRight: 20
  },
  section: {
    backgroundColor: lightThemeColor,
    color: 'grey',
    textTransform: 'capitalize'
  },
  itemAppointment: {
    padding: 20,
    backgroundColor: '#e0e0e0',
    borderBottomWidth: 1,
    borderBottomColor: 'lightgrey',
    flexDirection: 'row',
    borderRadius: 7,
  },
  itemEventRecord: {
    padding: 20,
    backgroundColor: '#c0cbe8',
    borderBottomWidth: 1,
    borderBottomColor: 'lightgrey',
    flexDirection: 'row',
    borderRadius: 7,
  },
  itemTitle:{
    color: 'rgb(51, 183, 226)',
    fontWeight : 'bold',
  },
  itemHourText: {
    color: 'black'
  },
  itemDurationText: {
    color: 'grey',
    fontSize: 12,
    marginTop: 4,
    marginLeft: 4
  },
  itemTitleText: {
    color: 'black',
    marginLeft: 16,
    fontWeight: 'bold',
    fontSize: 16
  },
  itemButtonContainer: {
    flex: 1,
    alignItems: 'flex-end',
    padding:2
  },
  emptyItem: {
    paddingLeft: 20,
    height: 52,
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: 'lightgrey'
  },
  emptyItemText: {
    color: 'lightgrey',
    fontSize: 14
  }
});