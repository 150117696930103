import React from 'react'

import EventRecord from '../../components/EventRecord';

const RecordPatientScreen = ({route}) => {
    return (<>
        <EventRecord route={route} />
    </>)
}

export default RecordPatientScreen
