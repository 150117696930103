import { useNavigation } from '@react-navigation/native';
import { useStoreState } from 'easy-peasy';
import React from 'react'
import { View, Text, StyleSheet, ScrollView, FlatList, Dimensions, TouchableOpacity, useWindowDimensions } from 'react-native'
// import { Calendar, CalendarList, ExpandableCalendar, Timeline, WeekCalendar } from 'react-native-calendars';
import { Avatar } from 'react-native-elements';

// const {width} = Dimensions.get('window')

const PatientsTherapistScreen = () => {
  const dataPatients = useStoreState( state => state.dataPatients)
  const navigation = useNavigation()
  const {width} = useWindowDimensions();


  const listNewItems = dataPatients.map((d,i) =>
  ( <>{d.therapylink.accepted ? null : (<TouchableOpacity onPress={() => navigation.navigate('PatientTherapistStackScreen', {d})}>
    <View style={[styles.view,{ width: width*0.2 ,alignItems:'center', justifyContent:'center', alignContent:'center'}]} >
          <Avatar source={ { uri: d.avatar}} rounded={true} size={75} />
       <Text style={{paddin:5, fontWeight:'bold'}}>{"\n"}{d.name}</Text>
       <Text style={{color:'white', fontWeight:'bold', backgroundColor:'#3e5ba6', padding:5, borderRadius:10}}>¡Nuevo!</Text>
       </View>
       </TouchableOpacity>)}</>)
  )
  const listPreviousItems = dataPatients.map((d,i) =>
  ( <>{d.therapylink.accepted ? (<TouchableOpacity onPress={() => navigation.navigate('PatientTherapistStackScreen', {d})}>
    <View style={[styles.view,{ width: width*0.2 ,alignItems:'center', justifyContent:'center', alignContent:'center'}]} >
          <Avatar source={ { uri: d.avatar}} rounded={true} size={75} />
       <Text style={{paddin:5, fontWeight:'bold'}}>{"\n"}{d.name}</Text>
       {/* {d.therapylink.accepted ? null : (<Text style={{color:'#3e5ba6', fontWeight:'bold'}}>¡Nuevo!</Text>)} */}
       </View>
       </TouchableOpacity>) : null }</>)
  )

    return (

            <ScrollView >
                      <View style={{
            flex: 1, flexWrap:'wrap', flexDirection:'row', alignItems:'center', justifyContent:'center'
        }}> 
              {listNewItems}{listPreviousItems}
                 </View>   
           
            </ScrollView>
          
    )
}

const styles = StyleSheet.create({

  view: {
      height:200,
      // width: 280 ,
      margin : 5,
      backgroundColor:"white",
      borderRadius:15,
      elevation:10,
      padding:10

  },

});
export default PatientsTherapistScreen
