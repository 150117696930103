import 'react-native-gesture-handler';
import React, { useState, useEffect } from 'react';
import {
  TouchableOpacity, View, Text, StyleSheet, Button
} from 'react-native';
import { createStore, StoreProvider, useStoreState, useStoreActions } from 'easy-peasy';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';
import ProfileStackScreen from '../Profile';
import DashTherapistStackScreen from './DashTherapist';
import CalendarTherapistStackScreen from './CalendarTherapist';
import ChatTherapistStackScreen from './ChatTherapist';
import PatientsTherapistStackScreen from './PatientsTherapist';
import PatientTherapistStackScreen from './PatientTherapist';
import axios from 'axios';
import { API_URL } from '../../../core/api';
import AvatarDrawer from '../../../components/AvatarDrawer';
import { Icon } from 'react-native-elements';

function DrawerContent({navigation}) {
  
  const signOut = useStoreActions((action) => action.signOut);
  const handleSignOut = () => {
    signOut();
  }

  const Config = useStoreState(state => state.AuthConfig)
  const setData = useStoreActions(action => action.setData)
  const CheckNotificacion = () => {
      axios.get(API_URL+'chat/notification', Config)
      .then(res => {
        let payload = {}
        payload.notifications = res.data
        setData(payload)
        console.log('Checking for notifications')
      })
      .catch( error => console.log(error))
  }



    useEffect(() => CheckNotificacion())
    
    return (
      <DrawerContentScrollView style={{flexGrow:1, flexDirection: 'column' }}>

        <View style={styles.userInfoSection}>
          <TouchableOpacity  style={{padding: 10, flexDirection:"row"}} 
              onPress={() => handleSignOut()}>
              <Text style={{color:"#666666"}}>Cerrar sesión (Terapeuta)  </Text>
              <Icon name="poweroff" type="antdesign" size={15} color={"#666666"}/>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => navigation.navigate('ProfileStackScreen')}>
            <AvatarDrawer />
          </TouchableOpacity>
        </View>

          <DrawerItem
            label="Inicio"
            onPress={() => { navigation.navigate('DashTherapistStackScreen'); }}
          />
           <DrawerItem
            label="Pacientes"
            onPress={() => { navigation.navigate('PatientsTherapistStackScreen'); }}
          />
          <DrawerItem
            label="Agenda"
            onPress={() => { navigation.navigate('CalendarTherapistStackScreen'); }}
          />
          <DrawerItem
            label="Chat"
            onPress={() => { navigation.navigate('ChatTherapistStackScreen'); }}
          />
          
    </DrawerContentScrollView>
    );
  }
  
  
  const styles = StyleSheet.create({
    drawerContent: {
      //flex: 1,
    },
    userInfoSection: {
      flex:1
      //paddingLeft: 20,
    },
    title: {
      marginTop: 20,
      fontWeight: 'bold',
    },
    caption: {
      fontSize: 14,
      lineHeight: 14,
    },
    row: {
      marginTop: 20,
      flexDirection: 'row',
      alignItems: 'center',
    },
    section: {
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: 15,
    },
    paragraph: {
      fontWeight: 'bold',
      marginRight: 3,
    },
    drawerSection: {
      marginTop: 15,
    },
    preference: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingVertical: 12,
      paddingHorizontal: 16,
    },
  });
  
  
  

  const DrawerStack = createDrawerNavigator(); // Here comes the left stack list of links in the drawer
const DrawerTherapistStackScreen = () => {             // Haty que crear un stack adhoc para cada uno de los que aquí creemos. 
  return ( 
    <DrawerStack.Navigator 
    screenOptions={{
      headerShown: false
    }} // ADDED to prevent double bar // add on ReactUpgrade
    drawerContent={props => <DrawerContent {...props} />}>
     
      <DrawerStack.Screen
          name={"DashTherapistStackScreen"}
          component={DashTherapistStackScreen}
      />
      <DrawerStack.Screen
          name={"ProfileStackScreen"}
          component={ProfileStackScreen}
      />
      <DrawerStack.Screen
          name={"CalendarTherapistStackScreen"}
          component={CalendarTherapistStackScreen}
      />
      <DrawerStack.Screen
          name={"ChatTherapistStackScreen"}
          component={ChatTherapistStackScreen}
      />
      <DrawerStack.Screen
          name={"PatientsTherapistStackScreen"}
          component={PatientsTherapistStackScreen}
      />
      <DrawerStack.Screen
          name={"PatientTherapistStackScreen"}
          component={PatientTherapistStackScreen}
      />
    </DrawerStack.Navigator>
  )
}

export default DrawerTherapistStackScreen;