import { BlurView } from "expo-blur";
import React, { useEffect, useState } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { LinearGradient } from 'expo-linear-gradient';
import cookie from '../../../assets/icons/cookie.png'
import { Button } from "react-native-elements";
import AsyncStorage from "@react-native-async-storage/async-storage";


function Cookies (){
    
    const [cookieAccepted, setCookieAccepted] = useState(true)

    async function handleAccept () {
         console.log('Accepting Cookies')
        await AsyncStorage.setItem("cookieAccepted", true)
        setCookieAccepted(true)
    }
    async function checkAcceptance () {
        console.log('CheckingAcceptanceCookies')
        const acceptance = await AsyncStorage.getItem("cookieAccepted")
        console.log('Cookies previously accepted: ', acceptance)
        acceptance == 'undefined' || acceptance == null ? setCookieAccepted(false) : setCookieAccepted(true)
    }


    useEffect(()=> {checkAcceptance(), console.log('UseeffectCookies')}, [])
    
    const BannerCookies = () =>{
    return(<View style={{minHeight:50, width:"100%", backgroundColor:'white'}}>              
            <LinearGradient 
        // Linear Gradient  - In PRO put picture
        //colors={['#3e5ba6', '#444cb3', '#843361'] }
        colors={['#6ba4e1', '#4f6fbd', '#b362d5'] } //8c6adf
        //color = {['#8482e0', '#ab93d8', '#a433d2']}
        style={{width:"100%", height:'100%', flexDirection:'row'}}
        end={{ x: 0.1, y: 0.8 }}
        >
            <Image source={cookie} style={{width:45, height:45, marginHorizontal:10, marginVertical:'auto'}} />
            
            <Text style={{color: 'white', marginVertical:'auto', paddingVertical:5, marginRight:'auto'}}>
                <Text style={{fontWeight:'bold'}}>Valoramos tu privacidad{'\n'}</Text>
                Usamos cookies para mejorar su experiencia de navegación en el sitio web. Si continúa en la página y/o hace click en "Aceptar", usted presta su consentimiento. Haz click para ver nuestra <Text onPress={() => window.location='/politicadecookies'} style={{fontWeight:'bold'}}>política de cookies</Text>.
            </Text>
            
            <TouchableOpacity
              onPress={() => handleAccept()}
              >  
            <View style={{ backgroundColor: 'white', borderRadius: 10, margin: 5, flexDirection:'row', justifyContent: "space-between", alignItems: "center", marginHorizontal:15, marginVertical:'auto' }}>
                  <Text style={{color:"#b362d5", fontSize:16, fontWeight:"bold", padding:10}}>Aceptar</Text>
            </View>
            </TouchableOpacity>

        </LinearGradient>
        </View>)
    }
    

    return( <>{cookieAccepted ? null : <BannerCookies />} </>)
}
export default Cookies;

// Utilizamos Cookie
// Usamos cookies para mejorar su experiencia de navegación en nuestra webkitURL, para mostrarle contenidos personalizados y para analizar el tráfico en nuestra web.
