import React, { useEffect, useState } from "react";
import { View, Text, Linking, Image, useWindowDimensions } from "react-native";
import {BlurView} from "expo-blur";
import logo from '../../../assets/LogoPSYKEHUBlowr.png'
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../core/api";

function Emailverify (props) {
    const {width, height} = useWindowDimensions()
    const {user_id, confirmation_token} = useParams()
    const [verified, setVerified] = useState(false)

    const VerifyEmail = () => {
            console.log('Emailverify in process');
            axios.get(API_URL+'user/emailverify/'+user_id+'/'+confirmation_token)
            .then( () => setVerified(true) )
            // .catch( () => setError(true))
          }
    useEffect(() => VerifyEmail(), [])

    if (verified) {   
    return(<>
        <Image source={logo} style={{width:750, height:450, position:'absolute', left:(width/2)-300, top:(height/2)-225}} />
            <BlurView intensity={90} tint={"light"}
             style={{padding:100, borderRadius:20, margin:'auto', alignItems:'center', justifyContent:'center', width:"100%"}} >
               <Text style={{fontSize:20}}><Text style={{fontWeight:'bold'}}>¡Tu cuenta ha sido verificada correctamente!</Text>{"\n"}{"\n"} Ya puedes acceder con tu nombre de usuario y contraseña. {"\n"}{"\n"}{"\n"}</Text>
               <Text onPress={() => Linking.openURL('/')} style={{color:'#3e5ba6'}}>Volver al inicio {'->'}</Text>
            </BlurView>
        </>
    )
    }else{
    return(<>
        <Image source={logo} style={{width:750, height:450, position:'absolute', left:(width/2)-300, top:(height/2)-225}} />
            <BlurView intensity={90} tint={"light"}
             style={{padding:100, borderRadius:20, margin:'auto', alignItems:'center', justifyContent:'center', width:"100%"}} >
               <Text style={{fontSize:20}}>No hemos encontrado ninguna cuenta para verificar asociada a este enlace. Revisa el enlace de tu correo electrónico.{"\n"}{"\n"}</Text>
               <Text onPress={() => Linking.openURL('/')} style={{color:'#3e5ba6'}}>Volver al inicio {'->'}</Text>
            </BlurView>
        </>
    )
    }
 
}
export default Emailverify;