import React from 'react'

import EventAppointment from '../../components/EventAppointment';

const AppointmentPatientScreen = ({route}) => {
    return (<>
        <EventAppointment route={route} />
    </>)
}

export default AppointmentPatientScreen
